export default email => {
  // make only simple email validation for this demo
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return {
      valid: false,
      error: 'Please provide a valid e-mail address.',
    };
  }

  return {
    valid: true,
  };
};
