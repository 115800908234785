/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Nav, NavDropdown } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';

import { setLocale } from '../../actions/intl';

const MenuItem = NavDropdown.Item;

const flagStyle = {
  fontSize: '1.5em',
  verticalAlign: 'middle',
  paddingRight: '5px',
};

const localeDict = {
  /* @intl-code-template '${lang}-${COUNTRY}': '${Name}', */
  'en-US': (
    <>
      <ReactCountryFlag
        countryCode="GB"
        aria-label="English"
        svg
        style={flagStyle}
      />
    </>
  ),
  'de-DE': (
    <>
      <ReactCountryFlag
        countryCode="DE"
        aria-label="German"
        svg
        style={flagStyle}
      />
    </>
  ),
  /* @intl-code-template-end */
};

const localeName = locale => localeDict[locale] || locale;

class LanguageSwitcher extends React.Component {
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
    setLocale: PropTypes.func.isRequired,
    className: PropTypes.string,
    dropDownClassName: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    dropDownClassName: null,
  };

  render() {
    const { currentLocale, availableLocales, setLocale } = this.props;
    return (
      <Nav
        className={this.props.className}
        onSelect={e => {
          setLocale({ locale: e.slice('lang.'.length) });
        }}
      >
        <NavDropdown
          className={this.props.dropDownClassName}
          eventKey="lang"
          title={localeName(currentLocale)}
          id="locale-nav-dropdown"
          alignRight
        >
          {availableLocales.map(locale =>
            locale !== currentLocale ? (
              <MenuItem key={locale} eventKey={`lang.${locale}`}>
                {localeName(locale)}
              </MenuItem>
            ) : null,
          )}
        </NavDropdown>
      </Nav>
    );
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  setLocale,
};

export default connect(mapState, mapDispatch)(LanguageSwitcher);
