import React from 'react';

import LandingPage from 'components/LandingPage';

class LandingPageWrapper extends React.Component {
  render() {
    return <LandingPage />;
  }
}

export default LandingPageWrapper;
