exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3dttB .-YoKz{display:inline-block;vertical-align:middle;width:250px;max-width:50%;padding-right:10px;font-size:20px;color:#0db5ff;overflow:hidden}._3dttB .-YoKz._18CAZ{color:#b4b4b4}._3dttB .OplF4{display:inline-block;width:calc(50% - 250px);min-width:50%;padding-left:10px;vertical-align:middle}._3dttB .OplF4 [class^=rc-slider]{cursor:pointer}._3dttB .OplF4 [class^=rc-slider] [class^=rc-slider-rail]{background-color:#e5f7fe}._3dttB .OplF4 [class^=rc-slider] [class^=rc-slider-track]{background-color:#0db5ff}._3dttB .OplF4 [class^=rc-slider] [class^=rc-slider-handle]{background-color:#0db5ff;border-color:#0db5ff}._3dttB .OplF4 [class^=\"rc-slider rc-slider-disabled\"]{background-color:transparent}._3dttB .OplF4 [class^=\"rc-slider rc-slider-disabled\"] [class^=rc-slider-rail]{background-color:#efefef}._3dttB .OplF4 [class^=\"rc-slider rc-slider-disabled\"] [class^=rc-slider-track]{background-color:#b4b4b4}._3dttB .OplF4 [class^=\"rc-slider rc-slider-disabled\"] [class^=rc-slider-handle]{background-color:#b4b4b4;border-color:#b4b4b4}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"container": "_3dttB",
	"labelContainer": "-YoKz",
	"disabled": "_18CAZ",
	"sliderContainer": "OplF4"
};