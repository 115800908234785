exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1gkzt{width:100%;max-width:1920px;margin:auto;padding:0 0 100px}._1gkzt ._15Kdb{padding-top:30px;width:90%;max-width:1500px;margin:auto;text-align:right}._1gkzt ._15Kdb ._3WXVp{margin-right:calc(9% + 10px)}._1gkzt ._13xCV{width:90%;max-width:1500px;margin:auto;margin-top:50px}._1gkzt ._13xCV h1{color:#fff;margin-left:4%}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"recommendationTypePageContainer": "_1gkzt",
	"titleCheckboxContainer": "_15Kdb",
	"titleCheckbox": "_3WXVp",
	"widgetWrapper": "_13xCV"
};