import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Tab, Tabs } from 'react-bootstrap';

import s from './Tabs.scss';

class Tabs_ extends React.Component {
  static propTypes = {
    tab: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        child: PropTypes.arrayOf(PropTypes.node),
      }),
    ).isRequired,
    onSelect: PropTypes.func.isRequired,
    defaultActiveKey: PropTypes.string.isRequired,
  };

  render() {
    const { tab, defaultActiveKey, onSelect } = this.props;

    // if (currentTab == null) return <Loading />;
    return (
      <Tabs
        defaultActiveKey={defaultActiveKey}
        transition={false}
        id="Test-ID-for-testing-purposes-test"
        onSelect={onSelect}
      >
        {tab.map(tabinfo => (
          <Tab eventKey={tabinfo.key} title={tabinfo.key}>
            {tabinfo.child.map(children => children)}
          </Tab>
        ))}
      </Tabs>
    );
  }
}

export default withStyles(s)(Tabs_);
