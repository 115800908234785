import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import s from './Footer.scss';
import messages from './messages';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.renderLink = this.renderLink.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderLink(url, message) {
    return (
      <Link to={url}>
        <div className={s.footerItemWrapper}>
          <span>{message}</span>
        </div>
      </Link>
    );
  }

  render() {
    return (
      <div className={s.footer}>
        <div className={s.footerContentWrapper}>
          <div className={s.footerLeftItems}>
            {this.renderLink(
              `/imprint`,
              <FormattedMessage {...messages.imprint} />,
            )}
          </div>
          <div className={s.footerRightItems}>
            <div className={s.footerItemWrapper}>
              <a href="https://danube.ai/" target="_blank" rel="noreferrer">
                powered by danube.ai
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Footer);
