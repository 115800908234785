exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._8KqsO{width:100%;margin:0 -10px}._8KqsO ._2zk1h{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;width:100%}._8KqsO ._2zk1h ._1JgkP{position:relative;width:87%;margin-left:4%}._8KqsO ._2zk1h ._1JgkP ._1O9TY{display:inline-block;padding:10px;width:20%}._8KqsO ._2zk1h ._1JgkP .FRea3{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;position:absolute;top:0;bottom:0;left:0;right:0;padding:10px;font-size:24px;color:#fff;text-align:center}._8KqsO ._2zk1h .XwRp7{width:6%;height:auto;margin-left:2%;margin-right:1%;padding:0;border:3px solid #fff;border-radius:50%;background:none}._8KqsO ._2zk1h .XwRp7 ._2_Awf{width:100%;height:auto;color:#fff}._8KqsO ._2zk1h .XwRp7:hover{-webkit-box-shadow:0 0 15px hsla(0,0%,100%,.7);box-shadow:0 0 15px hsla(0,0%,100%,.7)}._8KqsO ._2zk1h .XwRp7:disabled{cursor:progress}._8KqsO ._2zk1h .XwRp7:disabled:hover{-webkit-box-shadow:none;box-shadow:none}._2U7_H{width:100%;margin-top:50px}._2U7_H ._2Z5Rr{border:none;border-radius:0;background:none}._2U7_H ._2Z5Rr .T6tP3{padding:0;margin-left:4%}._2U7_H ._2Z5Rr .T6tP3 ._3b_eh{padding:10px 0;height:100%;border:none;border-radius:0;background:none;font-size:30px;font-weight:700;color:#fff}._2U7_H ._2Z5Rr ._11yJK ._1vCLk{padding:0}._2U7_H ._2Z5Rr ._11yJK:not([class*=show]){display:block;height:0;overflow:hidden}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"widgetContainer": "_8KqsO",
	"widgetInner": "_2zk1h",
	"cardsContainer": "_1JgkP",
	"cardWrapper": "_1O9TY",
	"noMoreRecommendations": "FRea3",
	"continueButton": "XwRp7",
	"continueArrow": "_2_Awf",
	"accordion": "_2U7_H",
	"accordionCard": "_2Z5Rr",
	"accordionCardHeader": "T6tP3",
	"accordionCardToggle": "_3b_eh",
	"accordionCollapse": "_11yJK",
	"accordionCardBody": "_1vCLk"
};