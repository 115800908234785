import gql from 'graphql-tag';

export const recommendationTypesQuery = gql`
  query recommendationTypes {
    recommendationTypes {
      id
      key
      description
      i18n {
        id
        language
        description
      }
    }
  }
`;

export const recommendationTypeByIdQuery = gql`
  query recommendationTypeById($id: String!) {
    recommendationTypeById(id: $id) {
      id
      key
      description
      i18n {
        id
        language
        description
      }
    }
  }
`;

export const recommendationTypeByKeyQuery = gql`
  query recommendationTypeByKey($key: String!) {
    recommendationTypeByKey(key: $key) {
      id
      key
      description
      i18n {
        id
        language
        description
      }
    }
  }
`;
