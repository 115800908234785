export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const SET_LICENSE_STATE = 'SET_LICENSE_STATE';

export const USER_TOKEN_ID = 'id_token';
export const GUEST_DATA_ID = 'guest_data';

export const LOGGED_MODE_USER = 'LOGGED_MODE_USER';
export const LOGGED_MODE_GUEST = 'LOGGED_MODE_GUEST';

export const MATRIX_TYPE_LIKED = 'LIKED';
export const MATRIX_TYPE_DISLIKED = 'DISLIKED';

export const ITEM_RESET = 0;
export const ITEM_STATE_LIKED = 1;
export const ITEM_STATE_DISLIKED = 2;
export const ITEM_STATE_WISHLISTED = 3;
export const ITEM_STATE_SKIPPED = 4;

export const NUMBER_OF_RECOMMENDATIONS = 5;
export const NUMBER_OF_LIST_ITEMS = 5;

// danube rules
export const INVERSE_PERCENTAGE_SINE = 'INVERSE_PERCENTAGE_SINE';
export const PERCENTAGE = 'PERCENTAGE';
export const INVERSE_PERCENTAGE = 'INVERSE_PERCENTAGE';

export const ALWAYS_SHOW_TITLES_ID = 'always_show_titles';
export const TOGGLE_ALWAYS_SHOW_TITLES = 'TOGGLE_ALWAYS_SHOW_TITLES';
