exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3iY75{width:100%;max-width:1920px;margin:auto;padding:30px 0 100px}._3iY75 ._1smAE{width:90%;max-width:1500px;margin:auto;text-align:right}._3iY75 ._1smAE ._1vu0x{margin-right:calc(9% + 10px)}._3iY75 ._1Lrd7{width:90%;max-width:1500px;margin:auto;margin-top:50px}._3iY75 ._1Lrd7 h1{color:#fff;margin-left:4%}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"landingPageContainer": "_3iY75",
	"titleCheckboxContainer": "_1smAE",
	"titleCheckbox": "_1vu0x",
	"widgetWrapper": "_1Lrd7"
};