export default username => {
  // make only simple username validation for this demo
  if (username.length < 4 || username.length > 20) {
    return {
      valid: false,
      error: 'Your username must be between 4 to 20 characters long.',
    };
  }

  return {
    valid: true,
  };
};
