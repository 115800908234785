import gql from 'graphql-tag';

export const recommendationItemByIdQuery = gql`
  query recommendationItemById($id: String!) {
    recommendationItemById(id: $id) {
      id
      externalApiId
      externalApiLink
      title
      subTitle
      image
      releaseDate
      popularity
      i18n {
        id
        language
        title
        subTitle
      }
    }
  }
`;

export const recommendationItemsByIdsQuery = gql`
  query recommendationItemsByIds($ids: [String]!, $limit: Int, $offset: Int) {
    recommendationItemsByIds(ids: $ids, limit: $limit, offset: $offset)
      @connection(key: "recommendationItemsByIds", filter: ["ids"]) {
      recommendationItems {
        id
        externalApiId
        externalApiLink
        title
        subTitle
        image
        releaseDate
        popularity
        i18n {
          id
          language
          title
          subTitle
        }
      }
      count
    }
  }
`;

export const recommendationItemsByKeyQuery = gql`
  query recommendationItemsByKey($recommendationTypeKey: String!) {
    recommendationItemsByKey(recommendationTypeKey: $recommendationTypeKey) {
      id
      externalApiId
      externalApiLink
      title
      subTitle
      image
      releaseDate
      popularity
      i18n {
        id
        language
        title
        subTitle
      }
    }
  }
`;

export const recommendationTitleByKeyQuery = gql`
  query recommendationTitleByKeyQuery($recommendationTypeKey: String!) {
    recommendationItemsByKey(recommendationTypeKey: $recommendationTypeKey) {
      id
      title
      i18n {
        id
        language
        title
      }
    }
  }
`;
