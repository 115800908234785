import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import _ from 'lodash';

import Loading from '../Loading';
import {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
  ITEM_RESET,
} from '../../constants';
import {
  getLocalStorageGuestData,
  setLocalStorageGuestData,
} from '../Login/cookieAndLocalStorage';
import { userDataByKeyQuery } from '../../data/queries/userQueries';
import {
  userPreferencesByKeyQuery,
  getNewGuestPreferencesQuery,
} from '../../data/queries/userPreferencesQueries';
import { updateUserPreferencesMutation } from '../../data/mutations/userPreferencesMutation';
import { calculateGuestBenchmarkQuery } from '../../data/queries/benchMarkQueries';
import { addUserAnswersMutation } from '../../data/mutations/userMutations';

const buildUserDataMap = userData =>
  userData.recommendationItemIds.reduce((map, itemId, index) => {
    // eslint-disable-next-line no-param-reassign
    map[itemId] = userData.answers[index];
    return map;
  }, {});

const UserDataWrapper = WrappedComponent => {
  class WithUserData extends React.Component {
    static propTypes = {
      loggedMode: PropTypes.string.isRequired,
      recommendationTypeKey: PropTypes.string.isRequired,
      userDataByKeyData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        userDataByKey: PropTypes.shape({
          recommendationItemIds: PropTypes.array, // eslint-disable-line react/forbid-prop-types
          answers: PropTypes.array, // eslint-disable-line react/forbid-prop-types
        }),
      }),
      userPreferencesByKeyData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        userPreferences: PropTypes.shape({
          recommendationTypeId: PropTypes.string.isRequired,
          popularity: PropTypes.number.isRequired,
          positiveCorrelation: PropTypes.number.isRequired,
          negativeCorrelation: PropTypes.number.isRequired,
          age: PropTypes.number.isRequired,
          seenCount: PropTypes.number.isRequired,
        }),
      }).isRequired,
    };

    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    static defaultProps = {
      userDataByKeyData: null,
    };

    constructor(props) {
      super(props);

      this.state = {
        userData: null,
        locked: false,
      };

      this.refetchUserData = this.refetchUserData.bind(this);
      this.updateUserData = this.updateUserData.bind(this);
      this.updateUserPreferences = this.updateUserPreferences.bind(this);
      this.lockUserData = this.lockUserData.bind(this);
      this.unlockUserData = this.unlockUserData.bind(this);
    }

    componentDidMount() {
      const { loggedMode, recommendationTypeKey } = this.props;

      if (loggedMode === LOGGED_MODE_GUEST) {
        const guestData = getLocalStorageGuestData();
        const userData = guestData.userData[recommendationTypeKey];
        if (!guestData.userPreferences) guestData.userPreferences = {};
        if (!guestData.userPreferences[recommendationTypeKey])
          guestData.userPreferences[recommendationTypeKey] = {};
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          userData: {
            recommendationItemIds: userData ? Object.keys(userData) : [],
            answers: userData ? Object.values(userData) : [],
          },
        });
      }
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.recommendationTypeKey !== this.props.recommendationTypeKey
      ) {
        this.refetchUserData();
      }
    }

    async refetchUserData() {
      const { loggedMode, recommendationTypeKey } = this.props;

      if (loggedMode === LOGGED_MODE_USER) {
        // refetch query
        await this.context.client.query({
          query: userDataByKeyQuery,
          variables: {
            recommendationTypeKey,
          },
          fetchPolicy: 'network-only',
        });
      } else if (loggedMode === LOGGED_MODE_GUEST) {
        const guestData = getLocalStorageGuestData();
        const userData = guestData.userData[recommendationTypeKey];
        this.setState({
          userData: {
            recommendationItemIds: userData ? Object.keys(userData) : [],
            answers: userData ? Object.values(userData) : [],
          },
        });
      }
    }

    async refetchUserPreferences() {
      const { loggedMode, recommendationTypeKey } = this.props;
      if (loggedMode === LOGGED_MODE_USER) {
        // refetch query
        await this.context.client.query({
          query: userPreferencesByKeyQuery,
          variables: {
            recommendationTypeKey,
          },
          fetchPolicy: 'network-only',
        });
      }
    }

    async updateUserData({
      recommendationItemIds,
      answers,
      updateUserBenchmark,
    }) {
      const { loggedMode, recommendationTypeKey } = this.props;

      if (loggedMode === LOGGED_MODE_USER) {
        const response = await this.context.client.mutate({
          mutation: addUserAnswersMutation,
          variables: {
            userAnswers: {
              recommendationTypeKey,
              recommendationItemIds,
              answers,
              updateUserBenchmark,
            },
          },
        });

        if (response?.data?.addUserAnswers === true) {
          await this.refetchUserData();
        }
      } else if (loggedMode === LOGGED_MODE_GUEST) {
        const guestData = getLocalStorageGuestData();

        // update user data
        if (!guestData.userData) {
          guestData.userData = {};
        }

        const userData = guestData.userData[recommendationTypeKey] || {};

        const newItems = {};
        const removedItems = [];

        recommendationItemIds.forEach((id, index) => {
          // eslint-disable-next-line no-param-reassign
          if (answers[index] === ITEM_RESET) {
            removedItems.push(id);
          } else {
            newItems[id] = answers[index];
          }
        });

        const newData = {
          ...userData,
          ...newItems,
        };
        removedItems.forEach(itemId => {
          delete newData[itemId];
        });

        guestData.userData[recommendationTypeKey] = newData;

        // update benchmark
        if (!guestData.userBenchmark) {
          guestData.userBenchmark = {};
        }

        const userBenchmarkData =
          guestData?.userBenchmark[recommendationTypeKey]?.data || null;

        const response = await this.context.client.mutate({
          mutation: calculateGuestBenchmarkQuery,
          variables: {
            guestBenchmarkData: {
              benchmarkData: userBenchmarkData
                ? JSON.stringify(userBenchmarkData)
                : null,
              answers,
            },
          },
        });

        if (response?.data?.calculateGuestBenchmark) {
          const {
            benchmarkData,
            dampFactor,
          } = response?.data?.calculateGuestBenchmark;
          guestData.userBenchmark[recommendationTypeKey] = {
            data: JSON.parse(benchmarkData),
            dampFactor,
          };
        }

        setLocalStorageGuestData(guestData);

        await this.refetchUserData();
      }
    }

    async updateUserPreferences({ answers, danubeItems }) {
      const { loggedMode, recommendationTypeKey } = this.props;

      if (loggedMode === LOGGED_MODE_USER) {
        const response = await this.context.client.mutate({
          mutation: updateUserPreferencesMutation,
          variables: {
            userPreferences: {
              recommendationTypeKey,
              answers,
              danubeItems,
            },
          },
        });
        if (response?.data?.updateUserPreferences === true) {
          await this.refetchUserPreferences();
        }
      } else {
        const guestData = getLocalStorageGuestData();

        // update user data
        if (!guestData.userPreferences) {
          guestData.userPreferences = {};
        }
        const response = await this.context.client.query({
          query: getNewGuestPreferencesQuery,
          variables: {
            userPreferences: {
              currentPreferences:
                guestData.userPreferences[recommendationTypeKey] || {},
              recommendationTypeKey,
              answers,
              danubeItems,
            },
          },
        });
        const newPreferences = response?.data?.getNewGuestPreferences || {};
        guestData.userPreferences[recommendationTypeKey] = _.omit(
          newPreferences,
          'id',
          '__typename',
        );
        setLocalStorageGuestData(guestData);
      }
    }

    lockUserData() {
      this.setState({ locked: true });
    }

    unlockUserData() {
      this.setState({ locked: false });
    }

    render() {
      const {
        loggedMode,
        userDataByKeyData,
        userPreferencesByKeyData,
        recommendationTypeKey,
      } = this.props;
      const { locked } = this.state;

      if (
        (userDataByKeyData && userDataByKeyData.loading) ||
        (userPreferencesByKeyData && userPreferencesByKeyData.loading)
      )
        return <Loading />;

      if (loggedMode === LOGGED_MODE_GUEST && this.state.userData === null) {
        return <Loading />;
      }

      let userData;
      let userDataAsMap;
      if (loggedMode === LOGGED_MODE_USER) {
        userData = userDataByKeyData.userDataByKey;
        userDataAsMap = buildUserDataMap(userDataByKeyData.userDataByKey);
      } else if (loggedMode === LOGGED_MODE_GUEST) {
        userData = this.state.userData;
        userDataAsMap = buildUserDataMap(this.state.userData);
      }

      let userPreferences;
      if (loggedMode === LOGGED_MODE_USER) {
        userPreferences = _.omit(
          userPreferencesByKeyData.userPreferences,
          '__typename',
          'id',
        );
      } else {
        const guestData = getLocalStorageGuestData();
        userPreferences =
          guestData.userPreferences[recommendationTypeKey] || {};
      }

      return (
        <WrappedComponent
          userData={userData}
          userDataIsLocked={locked}
          userDataAsMap={userDataAsMap}
          refetchUserData={this.refetchUserData}
          updateUserData={this.updateUserData}
          userPreferences={userPreferences}
          updateUserPreferences={this.updateUserPreferences}
          lockUserData={this.lockUserData}
          unlockUserData={this.unlockUserData}
          {...this.props}
        />
      );
    }
  }

  return compose(
    graphql(userDataByKeyQuery, {
      name: 'userDataByKeyData',
      options: props => ({
        variables: {
          recommendationTypeKey: props.recommendationTypeKey,
        },
      }),
      skip: props => props.loggedMode !== LOGGED_MODE_USER,
    }),
    graphql(userPreferencesByKeyQuery, {
      name: 'userPreferencesByKeyData',
      options: props => ({
        variables: {
          recommendationTypeKey: props.recommendationTypeKey,
        },
      }),
      skip: props => props.loggedMode !== LOGGED_MODE_USER,
    }),
  )(WithUserData);
};

export default UserDataWrapper;
