import { defineMessages } from 'react-intl';

const messages = defineMessages({
  noMoreRecommendations: {
    id: 'cardWidget.noMoreRecommendations',
    defaultMessage: 'No more recommendations',
    description: 'label for no more recommendations message',
  },
  likedItems: {
    id: 'cardWidget.likedItems',
    defaultMessage: 'Liked items',
    description: 'label for liked items message',
  },
  dislikedItems: {
    id: 'cardWidget.dislikedItems',
    defaultMessage: 'Disliked items',
    description: 'label for disliked items message',
  },
  whishlistedItems: {
    id: 'cardWidget.whishlistedItems',
    defaultMessage: 'Wishlist',
    description: 'label for wishlist message',
  },
  skippedItems: {
    id: 'cardWidget.skippedItems',
    defaultMessage: 'Skipped items',
    description: 'label for skipped items message',
  },
  searchItems: {
    id: 'cardWidget.searchItems',
    defaultMessage: 'Search for items',
    description: 'label for search items message',
  },
});

export default messages;
