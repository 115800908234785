export default password => {
  // make only simple password validation for this demo
  if (password.length < 8) {
    return {
      valid: false,
      error: 'The password needs to be at least 8 characters long.',
    };
  }

  return {
    valid: true,
  };
};
