/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';
import { withCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';

import s from './ShowTitlesCheckbox.scss';
import messages from './messages';
import { ALWAYS_SHOW_TITLES_ID } from '../../constants';

class ShowTitlesCheckbox extends React.Component {
  static propTypes = {
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    alwaysShowTitles: PropTypes.bool.isRequired,
    toggleAlwaysShowTitles: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.renderCheckbox = this.renderCheckbox.bind(this);
  }

  renderCheckbox() {
    const { cookies } = this.props;
    const alwaysShowTitles = cookies.get(ALWAYS_SHOW_TITLES_ID) === 'true';

    return (
      <div className={s.titleCheckboxContainer}>
        <input
          type="checkbox"
          id="alwaysShowTitlesCheckbox"
          checked={alwaysShowTitles}
          onClick={() => {
            const currentAlwaysShowTitles =
              cookies.get(ALWAYS_SHOW_TITLES_ID) === 'true';
            cookies.set(ALWAYS_SHOW_TITLES_ID, !currentAlwaysShowTitles);
          }}
        />
        <label htmlFor="alwaysShowTitlesCheckbox">
          <FormattedMessage {...messages.showTitles} />
        </label>
      </div>
    );
  }

  render() {
    return <div>{!isMobile && this.renderCheckbox()}</div>;
  }
}

export default compose(withCookies, withStyles(s))(ShowTitlesCheckbox);
