import { defineMessages } from 'react-intl';

const messages = defineMessages({
  showTitles: {
    id: 'showTitles',
    defaultMessage: 'Always show titles',
    description: 'label for show titles message',
  },
});

export default messages;
