import React from 'react';
import PropTypes from 'prop-types';

import { confirmUserMutation } from '../../data/mutations/userMutations';

function withConfirmUser(WrappedComponent) {
  class ConfirmUserWrapper extends React.Component {
    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);

      this.confirmUser = this.confirmUser.bind(this);
    }

    async confirmUser({ activationCode }) {
      const response = await this.context.client.mutate({
        mutation: confirmUserMutation,
        variables: { activationCode },
      });

      if (
        response &&
        response.data &&
        response.data.confirmUser &&
        response.data.confirmUser.success
      ) {
        return {
          success: true,
          token: response.data.confirmUser.token,
        };
      }

      return {
        success: false,
        token: null,
      };
    }

    render() {
      return (
        <WrappedComponent confirmUser={this.confirmUser} {...this.props} />
      );
    }
  }

  return ConfirmUserWrapper;
}

export default withConfirmUser;
