/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const registerMutation = gql`
  mutation register(
    $username: String!
    $password: String!
    $email: String!
    $guestData: String!
  ) {
    register(
      username: $username
      password: $password
      email: $email
      guestData: $guestData
    ) {
      success
    }
  }
`;

export const confirmUserMutation = gql`
  mutation confirmUser($activationCode: String!) {
    confirmUser(activationCode: $activationCode) {
      success
      token
    }
  }
`;

export const addUserAnswersMutation = gql`
  mutation addUserAnswers($userAnswers: UserAnswers!) {
    addUserAnswers(userAnswers: $userAnswers)
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPassword($usernameOrEmail: String!) {
    resetPassword(usernameOrEmail: $usernameOrEmail)
  }
`;

export const setPasswordMutation = gql`
  mutation setPassword($activationCode: String!, $password: String!) {
    setPassword(activationCode: $activationCode, password: $password) {
      success
      token
    }
  }
`;
