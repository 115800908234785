import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaHeart from 'react-icons/lib/fa/heart';
import FaBan from 'react-icons/lib/fa/ban';
import FaList from 'react-icons/lib/fa/list';
import FaTimes from 'react-icons/lib/fa/times-circle';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

// eslint-disable-next-line css-modules/no-unused-class
import s from './Card.scss';
import messages from './messages';
import {
  ITEM_STATE_LIKED,
  ITEM_STATE_DISLIKED,
  ITEM_STATE_WISHLISTED,
} from '../../constants';

class Card extends React.Component {
  static propTypes = {
    itemData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    itemState: PropTypes.number,
    onItemStateChange: PropTypes.func.isRequired,
    removable: PropTypes.bool,
    onRemoveItem: PropTypes.func,
    alwaysShowTitle: PropTypes.bool,
    locked: PropTypes.bool,
    currentLocale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    removable: false,
    onRemoveItem: () => {},
    alwaysShowTitle: false,
    itemState: null,
    locked: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      titleSize: -1,
      iconFontSize: -1,
      imageToLoad: this.props.itemData.image,
      loadedImage: false,
    };

    this.cardRef = React.createRef();
    this.lastCardSize = -1;

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // initial resize call
  }

  componentDidUpdate(prevProps) {
    // exchange image if different item
    // currently the Cards keep the key on the flipcard so the component
    // is not dismounted but we then have to handle new images
    if (prevProps.itemData.id !== this.props.itemData.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        imageToLoad: this.props.itemData.image,
        loadedImage: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize() {
    if (
      this.cardRef &&
      this.cardRef.current &&
      this.lastCardSize !== this.cardRef.current.offsetWidth
    ) {
      this.lastCardSize = this.cardRef.current.offsetWidth;

      this.setState({
        titleSize: this.lastCardSize / 15,
        iconFontSize: this.lastCardSize / 23,
      });
    }
  }

  render() {
    const {
      itemData,
      itemState,
      onItemStateChange,
      removable,
      onRemoveItem,
      alwaysShowTitle,
      locked,
      currentLocale,
    } = this.props;
    const { titleSize, iconFontSize, loadedImage, imageToLoad } = this.state;

    let { title } = itemData;
    if (currentLocale != null && itemData.i18n != null) {
      const i18n = itemData.i18n.find(i => i.language === currentLocale);
      if (i18n) title = i18n.title;
    }

    let cardBorderClass = '';
    if (itemState === ITEM_STATE_LIKED) {
      cardBorderClass = s.liked;
    } else if (itemState === ITEM_STATE_DISLIKED) {
      cardBorderClass = s.disliked;
    } else if (itemState === ITEM_STATE_WISHLISTED) {
      cardBorderClass = s.added;
    }

    let titleSizeMultiplier = 1;
    if (title.length > 30) {
      titleSizeMultiplier = 0.7;
    } else if (title.length > 25) {
      titleSizeMultiplier = 0.8;
    } else if (title.length > 20) {
      titleSizeMultiplier = 0.9;
    }

    const titleContent = (
      <div className={s.titleContent}>
        {titleSize > 0 && (
          <span
            style={{
              fontSize: `${titleSizeMultiplier * titleSize}px`,
              lineHeight: 1.2,
            }}
          >
            {title}
          </span>
        )}
        {itemData.releaseDate != null && titleSize > 0 && (
          <span
            style={{
              fontSize: `${titleSizeMultiplier * titleSize}px`,
              lineHeight: 1.2,
            }}
          >
            ({moment(itemData.releaseDate).year()})
          </span>
        )}
      </div>
    );
    return (
      <div className={`${s.card} ${cardBorderClass}`} ref={this.cardRef}>
        <div
          className={`${s.cardContent} ${alwaysShowTitle ? s.showTitle : ''}`}
        >
          <div className={s.imageContainer}>
            <img
              className={loadedImage ? s.showImage : s.hideImage}
              src={itemData.image}
              alt=""
              onLoad={() => {
                // loading is done only when the correct image was loaded
                if (imageToLoad === this.props.itemData.image) {
                  this.setState({ loadedImage: true });
                }
              }}
            />
            {!loadedImage && (
              <div className={s.loadingImage}>
                <div className={s.loading} />
              </div>
            )}
          </div>
          {itemState === ITEM_STATE_DISLIKED && (
            <div className={s.imageDislikeOverlay} />
          )}
          {itemData.externalApiLink ? (
            <a
              className={s.linkTitleContainer}
              href={itemData.externalApiLink}
              target="_blank"
              rel="noreferrer"
            >
              {titleContent}
            </a>
          ) : (
            <div className={s.titleContainer}>{titleContent}</div>
          )}
          <div className={s.ratingsContainer}>
            <button
              className={`${s.iconButton} ${
                itemState === ITEM_STATE_LIKED ? s.liked : ''
              }`}
              onClick={() => {
                onItemStateChange(ITEM_STATE_LIKED);
              }}
              disabled={locked}
            >
              <FaHeart className={s.icon} />
              {iconFontSize > 0 ? (
                <span
                  className={s.iconText}
                  style={{ fontSize: `${iconFontSize}px`, lineHeight: 1.2 }}
                >
                  <FormattedMessage {...messages.liked} />
                </span>
              ) : (
                <span className={s.iconText} />
              )}
            </button>
            <button
              className={`${s.iconButton} ${
                itemState === ITEM_STATE_DISLIKED ? s.disliked : ''
              }`}
              onClick={() => {
                onItemStateChange(ITEM_STATE_DISLIKED);
              }}
              disabled={locked}
            >
              <FaBan className={s.icon} />
              {iconFontSize > 0 ? (
                <span
                  className={s.iconText}
                  style={{ fontSize: `${iconFontSize}px`, lineHeight: 1.2 }}
                >
                  <FormattedMessage {...messages.disliked} />
                </span>
              ) : (
                <span className={s.iconText} />
              )}
            </button>
            <button
              className={`${s.iconButton} ${
                itemState === ITEM_STATE_WISHLISTED ? s.added : ''
              }`}
              onClick={() => {
                onItemStateChange(ITEM_STATE_WISHLISTED);
              }}
              disabled={locked}
            >
              <FaList className={s.icon} />
              {iconFontSize > 0 ? (
                <span
                  className={s.iconText}
                  style={{ fontSize: `${iconFontSize}px`, lineHeight: 1.2 }}
                >
                  <FormattedMessage {...messages.add} />
                </span>
              ) : (
                <span className={s.iconText} />
              )}
            </button>
          </div>
          {removable && (
            <button
              className={s.removeButton}
              onClick={() => {
                onRemoveItem();
              }}
              disabled={locked}
            >
              <FaTimes />
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  currentLocale: state.intl.locale,
});

export default compose(connect(mapState), withStyles(s))(Card);
