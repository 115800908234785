import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s, { cssflipdelay } from './FlipCard.scss';

export const flipDelay = cssflipdelay;

class FlipCard extends React.Component {
  static propTypes = {
    front: PropTypes.node.isRequired,
    back: PropTypes.node.isRequired,
    flipToggle: PropTypes.bool.isRequired,
    flipDelay: PropTypes.number,
  };

  static defaultProps = {
    flipDelay: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      flipped: false,
      currentTimeout: null,
      timeoutRunning: false,
    };

    this.flipCard = this.flipCard.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.flipToggle !== this.props.flipToggle) {
      if (this.state.timeoutRunning) {
        // correcting if clicked before animation is done
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          flipped: !this.state.flipped,
        });
      }
      clearTimeout(this.state.currentTimeout);
      const currentTimeout = setTimeout(this.flipCard, this.props.flipDelay);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentTimeout, timeoutRunning: true });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.currentTimeout);
  }

  flipCard() {
    this.setState({
      flipped: !this.state.flipped,
      timeoutRunning: false,
    });
  }

  render() {
    const { flipped } = this.state;
    const { front, back } = this.props;
    const isFlipped = flipped ? s.isFlipped : '';
    return (
      <div className={s.flipCard}>
        <div className={`${s.flipCardInner} ${isFlipped}`}>
          <div className={s.front}>{front}</div>
          <div className={s.back}>
            <div>{back}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(FlipCard);
