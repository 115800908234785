/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import FaArrowCircleRight from 'react-icons/lib/io/arrow-right-c';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaCaretRight from 'react-icons/lib/fa/caret-right';
import FaHeart from 'react-icons/lib/fa/heart';
import FaSearch from 'react-icons/lib/fa/search';
import FaBan from 'react-icons/lib/fa/ban';
import FaList from 'react-icons/lib/fa/list';
import MdRedo from 'react-icons/lib/md/redo';
import { compose } from 'react-apollo';
import { Accordion, Card as AccordionCard } from 'react-bootstrap';
import { withCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import s from './CardRecommendationWidget.scss';
import messages from './messages';
import withUserData from '../User/UserDataWrapper';
import GenericRecommendationItemList from '../GenericRecommendationItemList';
import Card from '../Card';
import SkeletonCard from '../Card/SkeletonCard';
import EmptyCard from '../Card/EmptyCard';
import SearchWidget from '../SearchWidget';

import { getRecommendationsQuery } from '../../data/queries/recommendationQueries';
import { addCorrelationsMutation } from '../../data/mutations/correlationMatrixMutations';
import FlipCard, { flipDelay } from '../FlipCard';
import {
  ITEM_STATE_SKIPPED,
  ITEM_STATE_LIKED,
  ITEM_STATE_DISLIKED,
  ITEM_STATE_WISHLISTED,
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
  NUMBER_OF_RECOMMENDATIONS,
  ALWAYS_SHOW_TITLES_ID,
} from '../../constants';

// for testing purposes
/*
import jsonGames from '../../../games.json';
*/

// fixed keys for react to keep track of the same cards in the dom-tree
// not sure if the same behaviour can be achieved with the iterator
const flipCards = [
  'flipcard_1',
  'flipcard_2',
  'flipcard_3',
  'flipcard_4',
  'flipcard_5',
];

class CardRecommendationWidget extends React.Component {
  static propTypes = {
    loggedMode: PropTypes.string.isRequired,
    recommendationTypeKey: PropTypes.string.isRequired,
    userData: PropTypes.shape({
      recommendationItemIds: PropTypes.array, // eslint-disable-line react/forbid-prop-types
      answers: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
    userDataIsLocked: PropTypes.bool.isRequired,
    userDataAsMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    lockUserData: PropTypes.func.isRequired,
    unlockUserData: PropTypes.func.isRequired,
    refetchUserData: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
    updateUserPreferences: PropTypes.func.isRequired,
    userPreferences: PropTypes.shape({
      recommendationTypeId: PropTypes.string.isRequired,
      popularity: PropTypes.number.isRequired,
      positiveCorrelation: PropTypes.number.isRequired,
      negativeCorrelation: PropTypes.number.isRequired,
      age: PropTypes.number.isRequired,
      seenCount: PropTypes.number.isRequired,
    }).isRequired,
    cookies: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showSearchItemsList: PropTypes.bool,
    showLikedItemsList: PropTypes.bool,
    showDislikedItemsList: PropTypes.bool,
    showWishlistedItemsList: PropTypes.bool,
    showSkippedItemsList: PropTypes.bool,
    onNextRecommendations: PropTypes.func,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static defaultProps = {
    showSearchItemsList: false,
    showLikedItemsList: false,
    showDislikedItemsList: false,
    showWishlistedItemsList: false,
    showSkippedItemsList: false,
    onNextRecommendations: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      danubeItems: null,
      recommendationItems: null,
      previousRecommendationItems: null,
      flipFrontBack: true,
      flipAnimate: false,
      itemStates: {},
      likedItemIds: [],
      dislikedItemIds: [],
      wishlistedItemIds: [],
      skippedItemIds: [],
      itemListsTypeKey: null, // hack to update RecommendationType and items in the same frame on embedded lists
      expandedItemLists: {},
    };

    this.updateItemLists = this.updateItemLists.bind(this);
    this.getNextRecommendationItems = this.getNextRecommendationItems.bind(
      this,
    ); // eslint-disable-line prettier/prettier
    this.onItemStateChange = this.onItemStateChange.bind(this);
    this.onContinueClick = this.onContinueClick.bind(this);
    this.onToggleCollapsedList = this.onToggleCollapsedList.bind(this);
    this.addCorrelations = this.addCorrelations.bind(this);
    this.renderCards = this.renderCards.bind(this);
    this.renderCollapsibleItemsList = this.renderCollapsibleItemsList.bind(
      this,
    ); // eslint-disable-line prettier/prettier
    this.renderLikedItemsList = this.renderLikedItemsList.bind(this);
    this.renderDislikedItemsList = this.renderDislikedItemsList.bind(this);
    this.renderWishlistedItemsList = this.renderWishlistedItemsList.bind(this);
    this.renderSkippedItemsList = this.renderSkippedItemsList.bind(this);

    this.createAnimationCards = this.createAnimationCards.bind(this);
  }

  componentDidMount() {
    this.getNextRecommendationItems();
    this.updateItemLists();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recommendationTypeKey !== this.props.recommendationTypeKey) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          loading: true,
          recommendationItems: null,
          previousRecommendationItems: null,
          itemStates: {},
        },
        () => {
          this.getNextRecommendationItems();
        },
      );
    }

    if (prevProps.userData !== this.props.userData) {
      this.updateItemLists();
    }
  }

  updateItemLists() {
    if (this.props.userData) {
      this.setState({
        likedItemIds: this.props.userData.recommendationItemIds.filter(
          (item, index) =>
            this.props.userData.answers[index] === ITEM_STATE_LIKED,
        ),
        dislikedItemIds: this.props.userData.recommendationItemIds.filter(
          (item, index) =>
            this.props.userData.answers[index] === ITEM_STATE_DISLIKED,
        ),
        wishlistedItemIds: this.props.userData.recommendationItemIds.filter(
          (item, index) =>
            this.props.userData.answers[index] === ITEM_STATE_WISHLISTED,
        ),
        skippedItemIds: this.props.userData.recommendationItemIds.filter(
          (item, index) =>
            this.props.userData.answers[index] === ITEM_STATE_SKIPPED,
        ),
        itemListsTypeKey: this.props.recommendationTypeKey,
      });
    }
  }

  async getNextRecommendationItems(forceNetworkRefetch = false) {
    const { loggedMode, userPreferences } = this.props;
    const { previousRecommendationItems, flipFrontBack } = this.state;

    const result = await this.context.client.query({
      query: getRecommendationsQuery,
      variables: {
        data: {
          recommendationTypeKey: this.props.recommendationTypeKey,
          guestUserData:
            loggedMode === LOGGED_MODE_GUEST
              ? JSON.stringify(this.props.userData)
              : null,
          userPreferences,
        },
      },
      fetchPolicy: forceNetworkRefetch ? 'network-only' : null,
    });

    // eslint-disable-next-line prettier/prettier
    const recommendationItems = result?.data?.getRecommendations?.recommendationItems
      ? result.data.getRecommendations.recommendationItems
      : [];
    const danubeItems = result?.data?.getRecommendations?.danubeItems
      ? result.data.getRecommendations.danubeItems
      : [];

    const shouldAnimate =
      (previousRecommendationItems && previousRecommendationItems.length > 0) ||
      previousRecommendationItems === null;

    // for testing purposes
    // check which recommended games are from new games
    /*
    if (this.props.recommendationTypeKey === 'video-games') {
      console.log('Recommendation items: ', recommendationItems);
      console.log('json games: ', jsonGames);
      const newExternalIDs = jsonGames.map(item => item.externalID.toString());
      const oldExternalIDs = recommendationItems.map(item =>
        item.externalApiId.toString(),
      );
      const includedGames = oldExternalIDs.map(oldId =>
        newExternalIDs.includes(oldId),
      );
      console.log('new game or not', includedGames);
      const numIncludedGames = includedGames.filter(value => value).length;
      console.log('number of included games: ', numIncludedGames);
    }
    */

    this.setState({
      loading: false,
      recommendationItems,
      danubeItems,
      flipAnimate: shouldAnimate
        ? !this.state.flipAnimate
        : this.state.flipAnimate,
      flipFrontBack: !shouldAnimate ? false : flipFrontBack,
      itemStates: { recommendationItems },
    });
    return recommendationItems;
  }

  onItemStateChange(itemId, itemState) {
    const { itemStates } = this.state;
    const newItemStates = { ...itemStates };
    if (newItemStates[itemId] === itemState) {
      delete newItemStates[itemId];
    } else {
      newItemStates[itemId] = itemState;
    }
    this.setState({ itemStates: newItemStates });
  }

  async onContinueClick() {
    const { recommendationItems, itemStates, danubeItems } = this.state;
    const { onNextRecommendations } = this.props;

    this.setState({
      loading: true,
      flipFrontBack: !this.state.flipFrontBack,
      previousRecommendationItems: this.state.recommendationItems,
    });

    const {
      updateUserData,
      lockUserData,
      unlockUserData,
      updateUserPreferences,
    } = this.props;

    const allItemIds = [];
    const allItemAnswers = [];
    recommendationItems.forEach(item => {
      if (item) {
        allItemIds.push(item.id);
        allItemAnswers.push(
          itemStates[item.id] != null
            ? itemStates[item.id]
            : ITEM_STATE_SKIPPED,
        );
      }
    });

    lockUserData();

    await updateUserPreferences({
      answers: allItemAnswers,
      danubeItems: danubeItems.map(danubeItem =>
        _.omit(danubeItem, ['__typename']),
      ),
    });

    await this.addCorrelations({
      correlatedRecommendationItemIds: allItemIds,
      correlatedAnswers: allItemAnswers,
    });

    await updateUserData({
      recommendationItemIds: allItemIds,
      answers: allItemAnswers,
      updateUserBenchmark: true,
    });

    unlockUserData();

    this.getNextRecommendationItems(true);

    onNextRecommendations();
  }

  onToggleCollapsedList({ listKey, expanded }) {
    const { expandedItemLists } = this.state;
    const newExpandedItemLists = { ...expandedItemLists };
    newExpandedItemLists[listKey] = expanded;
    this.setState({ expandedItemLists: newExpandedItemLists });
  }

  async addCorrelations({
    correlatedRecommendationItemIds,
    correlatedAnswers,
  }) {
    const { loggedMode, userData } = this.props;

    // TODO(andreas.roschal) - do not add correlations for guests for now (remove once Beta phase is over)
    if (loggedMode === LOGGED_MODE_USER) {
      const response = await this.context.client.mutate({
        mutation: addCorrelationsMutation,
        variables: {
          data: {
            recommendationItemIds: userData.recommendationItemIds,
            answers: userData.answers,
            correlatedRecommendationItemIds,
            correlatedAnswers,
          },
        },
      });

      return response;
    }

    return null;
  }

  createAnimationCards() {
    const { userDataIsLocked, cookies, recommendationTypeKey } = this.props;
    const {
      loading,
      recommendationItems,
      previousRecommendationItems,
      flipFrontBack,
      flipAnimate,
    } = this.state;

    const recommendationItemsCopy = recommendationItems
      ? [...recommendationItems]
      : [];
    const alwaysShowTitles =
      isMobile || cookies.get(ALWAYS_SHOW_TITLES_ID) === 'true';
    const skeletonCards = [];
    if (recommendationItems == null) {
      for (let i = 0; i < NUMBER_OF_RECOMMENDATIONS; i += 1) {
        skeletonCards.push(
          <div className={s.cardWrapper} key={`card-${i}`}>
            <SkeletonCard />
          </div>,
        );
      }
    }

    // fill with skeleton cards if no recommendations here (yet)
    const cardItems = recommendationItemsCopy || skeletonCards;
    // fill empty slots with undefineds => skeletoncard will be rendered
    if (cardItems.length < NUMBER_OF_RECOMMENDATIONS) {
      cardItems.push(...Array(NUMBER_OF_RECOMMENDATIONS - cardItems.length));
    }
    const cards = cardItems.map((item, i) => {
      // front of card is the previous entry before it gets flipped
      let front;
      let prev;
      if (previousRecommendationItems) {
        prev = previousRecommendationItems[i];
        if (prev) {
          front = (
            <Card
              key={
                // setting the keys so the same cards keep the same key => no unmount happens which is required for smooth transitions
                flipFrontBack ? `front_${flipCards[i]}` : `back_${flipCards[i]}`
              }
              itemData={prev}
              itemState={this.state.itemStates[prev.id]}
              onItemStateChange={newItemState => {
                this.onItemStateChange(prev.id, newItemState);
              }}
              alwaysShowTitle={alwaysShowTitles}
              locked={loading || userDataIsLocked}
            />
          );
        } else {
          front = <EmptyCard />;
        }
      } else {
        front = (
          // eslint-disable-next-line react/no-array-index-key
          <SkeletonCard key={`${recommendationTypeKey}-card-front-${i}`} />
        );
      }

      // back of card is the new entry that gets revealed
      let back;
      if (recommendationItems) {
        if (item) {
          back = (
            <Card
              key={
                // setting the keys so the same cards keep the same key => no unmount happens which is required for smooth transitions
                flipFrontBack ? `back_${flipCards[i]}` : `front_${flipCards[i]}`
              }
              itemData={item}
              itemState={this.state.itemStates[item.id]}
              onItemStateChange={newItemState => {
                this.onItemStateChange(item.id, newItemState);
              }}
              alwaysShowTitle={alwaysShowTitles}
              locked={loading || userDataIsLocked}
            />
          );
        } else {
          back = <EmptyCard />;
        }
      } else {
        front = (
          // eslint-disable-next-line react/no-array-index-key
          <SkeletonCard key={`${recommendationTypeKey}-card-back-${i}`} />
        );
      }
      return (
        <FlipCard
          key={flipCards[i]}
          front={flipFrontBack ? front : back} // have to exchange before animation
          back={flipFrontBack ? back : front} // have to exchange before animation
          flipToggle={flipAnimate}
          flipDelay={i * flipDelay}
          flipFrontBack={flipFrontBack}
        />
      );
    });
    return cards;
  }

  renderCards() {
    const { loading, recommendationItems } = this.state;
    const cards = [];
    if (loading || (recommendationItems && recommendationItems.length > 0)) {
      cards.push(...this.createAnimationCards());
    }

    if (cards.length === 0) {
      const emptyCards = [];
      for (let i = 0; i < NUMBER_OF_RECOMMENDATIONS; i += 1) {
        emptyCards.push(
          <div className={s.cardWrapper} key={`card-${i}`}>
            <EmptyCard />
          </div>,
        );
      }
      emptyCards.push(
        <div key="noMoreRecommendations" className={s.noMoreRecommendations}>
          <FormattedMessage {...messages.noMoreRecommendations} />
        </div>,
      );
      return emptyCards;
    }

    return cards;
  }

  renderCollapsibleSearchList({ listKey, onItemStateChange, header }) {
    const { userDataIsLocked, userDataAsMap, cookies } = this.props;
    const { itemListsTypeKey, expandedItemLists } = this.state;

    const alwaysShowTitles = cookies.get(ALWAYS_SHOW_TITLES_ID) === 'true';

    // Note(andreas.roschal): use this.state.itemListsTypeKey instead of this.props.recommendationTypeKey to prevent update issue being one frame off in embedded lists
    const expanded = expandedItemLists[listKey] === true;

    return (
      <Accordion
        className={s.accordion}
        onSelect={eventKey => {
          this.onToggleCollapsedList({
            listKey,
            expanded: eventKey === listKey,
          });
        }}
      >
        <AccordionCard className={s.accordionCard}>
          <AccordionCard.Header className={s.accordionCardHeader}>
            <Accordion.Toggle
              className={s.accordionCardToggle}
              eventKey={listKey}
            >
              {expanded ? <FaCaretDown /> : <FaCaretRight />} {header}
            </Accordion.Toggle>
          </AccordionCard.Header>
          <Accordion.Collapse
            className={s.accordionCollapse}
            eventKey={listKey}
          >
            <AccordionCard.Body className={s.accordionCardBody}>
              <SearchWidget
                recommendationTypeKey={itemListsTypeKey}
                userDataIsLocked={userDataIsLocked}
                userDataAsMap={userDataAsMap}
                onItemStateChange={onItemStateChange}
                alwaysShowTitles={alwaysShowTitles}
              />
            </AccordionCard.Body>
          </Accordion.Collapse>
        </AccordionCard>
      </Accordion>
    );
  }

  renderCollapsibleItemsList({ listKey, itemIds, onItemStateChange, header }) {
    const { userDataIsLocked, userDataAsMap, cookies } = this.props;
    const { itemListsTypeKey, expandedItemLists } = this.state;

    const alwaysShowTitles =
      isMobile || cookies.get(ALWAYS_SHOW_TITLES_ID) === 'true';

    // Note(andreas.roschal): use this.state.itemListsTypeKey instead of this.props.recommendationTypeKey to prevent update issue being one frame off in embedded lists
    const expanded = expandedItemLists[listKey] === true;

    return (
      <Accordion
        className={s.accordion}
        onSelect={eventKey => {
          this.onToggleCollapsedList({
            listKey,
            expanded: eventKey === listKey,
          });
        }}
      >
        <AccordionCard className={s.accordionCard}>
          <AccordionCard.Header className={s.accordionCardHeader}>
            <Accordion.Toggle
              className={s.accordionCardToggle}
              eventKey={listKey}
            >
              {expanded ? <FaCaretDown /> : <FaCaretRight />} {header}
            </Accordion.Toggle>
          </AccordionCard.Header>
          <Accordion.Collapse
            className={s.accordionCollapse}
            eventKey={listKey}
          >
            <AccordionCard.Body className={s.accordionCardBody}>
              <GenericRecommendationItemList
                recommendationTypeKey={itemListsTypeKey}
                itemIds={itemIds}
                userDataIsLocked={userDataIsLocked}
                userDataAsMap={userDataAsMap}
                onItemStateChange={onItemStateChange}
                itemsRemovable
                alwaysShowTitles={alwaysShowTitles}
              />
              <FlipCard />
            </AccordionCard.Body>
          </Accordion.Collapse>
        </AccordionCard>
      </Accordion>
    );
  }

  renderSearchList() {
    const { updateUserData, lockUserData, unlockUserData } = this.props;

    return this.renderCollapsibleSearchList({
      listKey: 'searchlist',
      onItemStateChange: async (itemId, newState) => {
        lockUserData();
        await this.addCorrelations({
          correlatedRecommendationItemIds: [itemId],
          correlatedAnswers: [newState],
        });
        await updateUserData({
          recommendationItemIds: [itemId],
          answers: [newState],
          updateUserBenchmark: false,
        });
        unlockUserData();
      },
      header: (
        <span>
          <FaSearch /> <FormattedMessage {...messages.searchItems} />
        </span>
      ),
    });
  }

  renderLikedItemsList() {
    const { updateUserData, lockUserData, unlockUserData } = this.props;
    const { likedItemIds } = this.state;

    return this.renderCollapsibleItemsList({
      listKey: 'likedList',
      itemIds: likedItemIds,
      onItemStateChange: async (itemId, newState) => {
        lockUserData();
        await this.addCorrelations({
          correlatedRecommendationItemIds: [itemId],
          correlatedAnswers: [newState],
        });
        await updateUserData({
          recommendationItemIds: [itemId],
          answers: [newState],
          updateUserBenchmark: false,
        });
        unlockUserData();
      },
      header: (
        <span>
          <FaHeart /> <FormattedMessage {...messages.likedItems} />
        </span>
      ),
    });
  }

  renderDislikedItemsList() {
    const { updateUserData, lockUserData, unlockUserData } = this.props;
    const { dislikedItemIds } = this.state;

    return this.renderCollapsibleItemsList({
      listKey: 'dislikedList',
      itemIds: dislikedItemIds,
      onItemStateChange: async (itemId, newState) => {
        lockUserData();
        await this.addCorrelations({
          correlatedRecommendationItemIds: [itemId],
          correlatedAnswers: [newState],
        });
        await updateUserData({
          recommendationItemIds: [itemId],
          answers: [newState],
          updateUserBenchmark: false,
        });
        unlockUserData();
      },
      header: (
        <span>
          <FaBan /> <FormattedMessage {...messages.dislikedItems} />
        </span>
      ),
    });
  }

  renderWishlistedItemsList() {
    const { updateUserData, lockUserData, unlockUserData } = this.props;
    const { wishlistedItemIds } = this.state;

    return this.renderCollapsibleItemsList({
      listKey: 'wishlistedList',
      itemIds: wishlistedItemIds,
      onItemStateChange: async (itemId, newState) => {
        lockUserData();
        await this.addCorrelations({
          correlatedRecommendationItemIds: [itemId],
          correlatedAnswers: [newState],
        });
        await updateUserData({
          recommendationItemIds: [itemId],
          answers: [newState],
          updateUserBenchmark: false,
        });
        unlockUserData();
      },
      header: (
        <span>
          <FaList /> <FormattedMessage {...messages.whishlistedItems} />
        </span>
      ),
    });
  }

  renderSkippedItemsList() {
    const { updateUserData, lockUserData, unlockUserData } = this.props;
    const { skippedItemIds } = this.state;

    return this.renderCollapsibleItemsList({
      listKey: 'skippedList',
      itemIds: skippedItemIds,
      onItemStateChange: async (itemId, newState) => {
        lockUserData();
        await this.addCorrelations({
          correlatedRecommendationItemIds: [itemId],
          correlatedAnswers: [newState],
        });
        await updateUserData({
          recommendationItemIds: [itemId],
          answers: [newState],
          updateUserBenchmark: false,
        });
        unlockUserData();
      },
      header: (
        <span>
          <MdRedo /> <FormattedMessage {...messages.skippedItems} />
        </span>
      ),
    });
  }

  render() {
    const { userDataIsLocked } = this.props;
    const {
      showSearchItemsList,
      showLikedItemsList,
      showDislikedItemsList,
      showWishlistedItemsList,
      showSkippedItemsList,
    } = this.props;
    const { loading } = this.state;
    return (
      <div className={s.widgetContainer}>
        <div className={s.widgetInner}>
          <div className={s.cardsContainer}>{this.renderCards()}</div>
          <button
            className={s.continueButton}
            onClick={this.onContinueClick}
            disabled={loading || userDataIsLocked}
          >
            <FaArrowCircleRight className={s.continueArrow} />
          </button>
        </div>
        {showSearchItemsList && this.renderSearchList()}
        {showWishlistedItemsList && this.renderWishlistedItemsList()}
        {showLikedItemsList && this.renderLikedItemsList()}
        {showDislikedItemsList && this.renderDislikedItemsList()}
        {showSkippedItemsList && this.renderSkippedItemsList()}
      </div>
    );
  }
}

export default compose(
  withUserData,
  withCookies,
  withStyles(s),
)(CardRecommendationWidget);
