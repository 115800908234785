exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3_n5s{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:100%;font-size:22px;padding-top:10vh}@media (max-width:1200px){._3_n5s{font-size:20px}}@media (max-width:992px){._3_n5s{font-size:18px}}@media (max-width:768px){._3_n5s{font-size:16px;padding-top:3vh}}._3_n5s h1{color:#fff}._2zjzu{padding:5px;background:none}._2zjzu,._2zjzu ._1grhE{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center}._2zjzu ._1NSa8{position:relative;margin:0;padding:20px}._2zjzu ._1NSa8 label{color:#fff}._2zjzu ._1NSa8 input{width:100%;display:block;margin:0 auto;border:none;border-bottom:1px solid #fff;background:none;color:#fff}._2zjzu ._1NSa8:last-child{margin-bottom:10px}._2zjzu ._2_o45{margin-top:2px;color:#ff5722}._2zjzu ._1TlXV{margin-top:2px;color:#fff}._2zjzu button{margin:10px 0}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"container": "_3_n5s",
	"form": "_2zjzu",
	"formContent": "_1grhE",
	"formGroup": "_1NSa8",
	"formError": "_2_o45",
	"formMessage": "_1TlXV"
};