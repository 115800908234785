import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import interpolate from 'color-interpolate';

import s from './MetricProgressBar.scss';

class MetricProgressBar extends React.Component {
  static propTypes = {
    userMetric: PropTypes.number.isRequired,
    isHidden: PropTypes.bool.isRequired,
  };

  render() {
    const { userMetric, isHidden } = this.props;

    const colormap = interpolate(['rgb(253, 80, 97)', 'rgb(106, 112, 254)']);
    const labelColor = colormap(userMetric / 100);

    return (
      <div
        className={s.userMetricsprogressBarContainer}
        style={{ opacity: isHidden ? 0 : 1 }}
      >
        <div
          className={s.userMetricsProgressBarFilled}
          style={{ width: `${userMetric}%` }}
        >
          <div className={s.backgroundHelper} />
          <div className={s.userMetricsLabelWrapper}>
            <div
              className={s.userMetricsLabel}
              style={{ backgroundColor: `${labelColor}` }}
            >
              {userMetric}%
            </div>
          </div>
        </div>
        <div className={s.userMetricsProgressBarNotFilled}>
          <div className={s.backgroundHelper} />
          <div className={s.userMetricsProgressBar} />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(MetricProgressBar);
