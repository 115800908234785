import Cookies from 'js-cookie';

import { USER_TOKEN_ID, GUEST_DATA_ID } from '../../constants';

export const setUserTokenCookie = token => {
  Cookies.set(USER_TOKEN_ID, token, { expires: 7 });
};

export const getUserTokenCookie = () => Cookies.get(USER_TOKEN_ID);

export const removeUserTokenCookie = () => Cookies.remove(USER_TOKEN_ID);

export const setLocalStorageGuestData = data => {
  localStorage.setItem(GUEST_DATA_ID, JSON.stringify(data));
};

export const getLocalStorageGuestData = () => {
  const data = localStorage.getItem(GUEST_DATA_ID);
  if (data != null) return JSON.parse(data);
  return {
    userData: {},
    userBenchmark: {},
    userPreferences: {},
  };
};

export const removeLocalStorageGuestData = () => {
  localStorage.removeItem(GUEST_DATA_ID);
};
