exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2nyMd{width:100%}._2nyMd ._1WUIN{width:100%;text-align:right}._2nyMd ._1WUIN ._1gnEe{margin-right:calc(9% + 10px);font-size:16px;color:#fff}._2nyMd ._3G1OI{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;width:100%}._2nyMd ._3G1OI ._3RhPu{position:relative;width:87%}._2nyMd ._3G1OI ._3RhPu ._3dHW4{display:inline-block;padding:10px;width:20%}._2nyMd ._3G1OI ._3RhPu .mVjzc{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;position:absolute;top:0;bottom:0;left:0;right:0;padding:10px;font-size:24px;color:#fff;text-align:center}._2nyMd ._3G1OI ._30kXZ,._2nyMd ._3G1OI .Y191M{width:4%;height:auto;padding:0;border:none;background:none}._2nyMd ._3G1OI ._30kXZ ._3y_SS,._2nyMd ._3G1OI .Y191M ._3y_SS{width:100%;height:auto;color:#fff}._2nyMd ._3G1OI ._30kXZ:disabled ._3y_SS,._2nyMd ._3G1OI .Y191M:disabled ._3y_SS{color:hsla(0,0%,100%,.25)}._2nyMd ._3G1OI .Y191M{margin-right:5%}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"genericCardsListWrapper": "_2nyMd",
	"pagesLabelContainer": "_1WUIN",
	"pageCounter": "_1gnEe",
	"genericCardsList": "_3G1OI",
	"cardsContainer": "_3RhPu",
	"cardWrapper": "_3dHW4",
	"noItemsInList": "mVjzc",
	"leftButton": "_30kXZ",
	"rightButton": "Y191M",
	"arrow": "_3y_SS"
};