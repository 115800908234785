exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3thcN,._3thcN:after{border-radius:50%;width:10em;height:10em}._3thcN{margin:60px auto;font-size:10px;position:relative;border-top:1.1em solid hsla(0,0%,61%,.2);border-right:1.1em solid hsla(0,0%,61%,.2);border-bottom:1.1em solid hsla(0,0%,61%,.2);border-left:1.1em solid #0db5ff;-webkit-transform:translateZ(0);-ms-transform:translateZ(0);transform:translateZ(0);-webkit-animation:_2uO0h 1.1s infinite linear;animation:_2uO0h 1.1s infinite linear}@-webkit-keyframes _2uO0h{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes _2uO0h{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"loader": "_3thcN",
	"load8": "_2uO0h"
};