import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

import s from './RecommendationTypePage.scss';
import { LOGGED_MODE_USER, LOGGED_MODE_GUEST } from '../../constants';
import { getLocalStorageGuestData } from '../Login/cookieAndLocalStorage';
import messages from './messages';
import withAuthentication from '../Auth/AuthenticationWrapper';
import CardRecommendationWidget from '../CardRecommendationWidget';
import ShowTitlesCheckbox from '../ShowTitlesCheckbox';
import MetricProgressBar from './MetricProgressBar';
import {
  getUserMetricsQuery,
  getGlobalMetricsQuery,
} from '../../data/queries/metricsQueries';

class RecommendationTypePage extends React.Component {
  static propTypes = {
    loggedMode: PropTypes.string.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        recommendationTypeKey: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      userMetric: null,
      loadingUserMetric: true,
    };

    this.fetchGlobalMetrics = this.fetchGlobalMetrics.bind(this);
    this.fetchUserMetrics = this.fetchUserMetrics.bind(this);
  }

  componentDidMount() {
    this.fetchUserMetrics();
  }

  // eslint-disable-next-line react/sort-comp
  componentWillUnmount() {
    if (this.blendTimeout) clearTimeout(this.blendTimeout);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.recommendationTypeKey !==
      this.props.match.params.recommendationTypeKey
    ) {
      this.fetchUserMetrics();
    }
  }

  async fetchGlobalMetrics() {
    // refetch metricsQuery manually (to update globalMetrics numbers in header)
    this.context.client.query({
      query: getGlobalMetricsQuery,
      fetchPolicy: 'network-only',
    });
  }

  async fetchUserMetrics() {
    const { loggedMode, match } = this.props;

    if (loggedMode === LOGGED_MODE_USER) {
      // refetch userMetricsQuery manually (to update progress bar)
      const result = await this.context.client.query({
        query: getUserMetricsQuery,
        variables: {
          recommendationTypeKey: match.params.recommendationTypeKey,
        },
        fetchPolicy: 'network-only',
      });

      if (result.data && result.data.getUserMetrics) {
        this.setState({
          userMetric: result.data.getUserMetrics.userMetric || 0,
          loadingUserMetric: false,
        });
      }
    } else if (loggedMode === LOGGED_MODE_GUEST) {
      const guestData = getLocalStorageGuestData();

      const userBenchmark = guestData?.userBenchmark[match.params.recommendationTypeKey]?.data; // eslint-disable-line prettier/prettier
      const dampFactor = guestData?.userBenchmark[match.params.recommendationTypeKey]?.dampFactor; // eslint-disable-line prettier/prettier
      const userMetric =
        userBenchmark != null
          ? Math.round(
              (1 - userBenchmark.value) *
                100 *
                (dampFactor != null ? dampFactor : 1),
            )
          : 0;

      // fix for blend-in
      if (this.blendTimeout) clearTimeout(this.blendTimeout);
      this.blendTimeout = setTimeout(() => {
        this.setState({
          userMetric,
          loadingUserMetric: false,
        });
      }, 0);
    }
  }

  render() {
    const { loggedMode, match } = this.props;
    const { userMetric, loadingUserMetric } = this.state;

    return (
      <div className={s.recommendationTypePageContainer}>
        <MetricProgressBar
          userMetric={userMetric}
          isHidden={loadingUserMetric}
        />
        <div className={s.titleCheckboxContainer}>
          <div className={s.titleCheckbox}>
            <ShowTitlesCheckbox />
          </div>
        </div>
        <div className={s.widgetWrapper}>
          <h1>
            <FormattedMessage {...messages.recommendations} />
          </h1>
          <CardRecommendationWidget
            key={`widget-${match.params.recommendationTypeKey}`}
            loggedMode={loggedMode}
            recommendationTypeKey={match.params.recommendationTypeKey}
            showSearchItemsList
            showLikedItemsList
            showDislikedItemsList
            showWishlistedItemsList
            showSkippedItemsList
            onNextRecommendations={() => {
              this.fetchGlobalMetrics();
              this.fetchUserMetrics();
            }}
          />
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withAuthentication,
  withStyles(s),
)(RecommendationTypePage);
