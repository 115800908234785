exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._26fLk{display:inline-block;padding:10px;width:20%;-webkit-perspective:1000px;perspective:1000px}.iVSoH{width:100%;height:100%;-webkit-transition:-webkit-transform .35s;transition:-webkit-transform .35s;-o-transition:transform .35s;transition:transform .35s;transition:transform .35s,-webkit-transform .35s;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;cursor:pointer}._276P2{margin:-166.6667% 0 0}._276P2,.iVSoH._2XumP{-webkit-transform:rotateY(180deg);transform:rotateY(180deg)}.iVSoH._2XumP ._3nkQj,.iVSoH._2XumP ._276P2{z-index:-1}.iVSoH>div{position:relative;width:100%;height:100%;border-radius:16px;-webkit-backface-visibility:hidden;backface-visibility:hidden}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"cssflipdelay": "50",
	"flipCard": "_26fLk",
	"flipCardInner": "iVSoH",
	"back": "_276P2",
	"isFlipped": "_2XumP",
	"front": "_3nkQj"
};