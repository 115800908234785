import gql from 'graphql-tag';

export const loginQuery = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      token
    }
  }
`;

export const meQuery = gql`
  query me {
    me {
      id
      username
      active
    }
  }
`;

export const userDataByKeyQuery = gql`
  query userDataByKey($recommendationTypeKey: String!) {
    userDataByKey(recommendationTypeKey: $recommendationTypeKey) {
      recommendationItemIds
      answers
    }
  }
`;
