/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withRouter } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';

import withLogin from './withLogin';
import withConfirmUser from './withConfirmUser';
import s from './Login.scss';
import Loading from '../Loading';
import withAuthentication from '../Auth/AuthenticationWrapper';
import messages from './messages';
import { LOGGED_MODE_USER } from '../../constants';
import { parseGetParameters } from '../../core/url';
import { isClientSide } from '../../util';
import Modal from '../Modal';

class Login extends React.Component {
  static propTypes = {
    loggedMode: PropTypes.string.isRequired,
    login: PropTypes.func.isRequired,
    loginViaToken: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    confirmUser: PropTypes.func.isRequired,
  };

  static renderErrorMessage(msg) {
    return <div className={s.formError}>{msg}</div>;
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loginErrors: [],
      loginToken: null,
      showUserActivatedModal: false,
    };

    this.activateUser = this.activateUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleTokenLogin = this.handleTokenLogin.bind(this);
  }

  componentDidMount() {
    if (isClientSide()) {
      // check if url contains activation code parameter
      const getParameters = parseGetParameters(this.props.location.search);
      if (getParameters.code != null) {
        this.activateUser(getParameters.code);
      }
    }
  }

  async activateUser(activationCode) {
    const { confirmUser } = this.props;

    this.setState({ loading: true });

    const result = await confirmUser({ activationCode });

    if (result && result.success) {
      this.setState({
        loading: false,
        loginErrors: [],
        loginToken: result.token,
        showUserActivatedModal: true,
      });
    } else {
      this.setState({
        loading: false,
        loginErrors: ['Failed to acivate user!'],
      });
    }
  }

  async handleTokenLogin() {
    const { loginViaToken } = this.props;
    const { loginToken } = this.state;

    await loginViaToken({ token: loginToken });

    window.location = '/';
  }

  async handleLogin(values, { setSubmitting }) {
    const { login } = this.props;
    setSubmitting(true);

    this.setState({ loading: true });

    const result = await login({
      username: values.username,
      password: values.password,
    });

    if (!result) {
      this.setState({
        loginErrors: ['Invalid credentials.'],
        loading: false,
      });
      setSubmitting(false);
    } else {
      window.location = '/';
    }
  }

  render() {
    const { loggedMode } = this.props;
    const { loading, loginErrors, showUserActivatedModal } = this.state;

    if (loggedMode === LOGGED_MODE_USER) {
      return <Redirect to="/" />;
    }

    return (
      <div className={s.container}>
        <Formik
          initialValues={{ username: '', password: '' }}
          validate={values => {
            const errors = {};
            if (!values.username) {
              errors.username = 'Required';
            }
            if (!values.password) {
              errors.password = 'Required';
            }
            return errors;
          }}
          onSubmit={this.handleLogin}
        >
          {({ isSubmitting }) => (
            <Form className={s.form}>
              <div className={s.formContent}>
                <fieldset>
                  <div className={s.formGroup}>
                    <label htmlFor="username">
                      <FormattedMessage {...messages.user} />
                    </label>
                    <Field type="text" id="username" name="username" />
                    <ErrorMessage
                      name="username"
                      render={Login.renderErrorMessage}
                    />
                  </div>
                  <div className={s.formGroup}>
                    <label htmlFor="password">
                      <FormattedMessage {...messages.password} />
                    </label>
                    <Field type="password" id="password" name="password" />
                    <ErrorMessage
                      name="password"
                      render={Login.renderErrorMessage}
                    />
                  </div>
                </fieldset>
                {loginErrors.length > 0 && (
                  <div>
                    {loginErrors.map(error => (
                      <div key={error} className={s.formError}>
                        {error}
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-ur btn-round btn-wide"
                  disabled={isSubmitting}
                >
                  <FormattedMessage {...messages.login} />
                </button>
                <div className={s.parentLinkDiv}>
                  <div className={s.childLinkDiv}>
                    <Link to="/register" style={{ color: 'white' }}>
                      <FormattedMessage {...messages.register} />
                    </Link>
                  </div>
                  <div className={s.childLinkDiv}>
                    <Link to="/resetpassword" style={{ color: 'white' }}>
                      <FormattedMessage {...messages.forgotPassword} />
                    </Link>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <Modal
          show={showUserActivatedModal}
          title={
            <div style={{ width: '100%', textAlign: 'center' }}>
              <h1>
                <FormattedMessage {...messages.modalTitle} />
              </h1>
            </div>
          }
          body={
            <p style={{ textAlign: 'center' }}>
              <FormattedMessage {...messages.modalBody} />
            </p>
          }
          hide={() => {
            this.handleTokenLogin();
          }}
        />
        {loading && <Loading />}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withRouter,
  withLogin,
  withConfirmUser,
  withStyles(s),
)(Login);
