import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { removeUserTokenCookie } from './cookieAndLocalStorage';

function withLogout(WrappedComponent) {
  class LogoutWrapper extends React.Component {
    static propTypes = {
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    };

    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);

      this.logout = this.logout.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    logout() {
      removeUserTokenCookie();
      window.location = '/login';
    }

    render() {
      return <WrappedComponent logout={this.logout} {...this.props} />;
    }
  }

  return withRouter(LogoutWrapper);
}

export default withLogout;
