import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';

import Loading from '../Loading';
import s from './UserPreferences.scss';
import Slider from '../Slider';
import { userPreferencesByKeyQuery } from '../../data/queries/userPreferencesQueries';
import { recommendationTypesQuery } from '../../data/queries/recommendationTypeQueries';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import PreferenceTabs from './Tabs';

const INTERVAL = { max: 100, min: 0 };

class UserPreferences extends React.Component {
  static propTypes = {
    recommendationTypesData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      recommendationTypes: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      userPreferences: [],
      loading: false,
    };
    this.fetchUserPreferences = this.fetchUserPreferences.bind(this);
    this.renderSliders = this.renderSliders.bind(this);
  }

  componentDidMount() {
    const {
      recommendationTypesData: { recommendationTypes },
    } = this.props;

    this.fetchUserPreferences(recommendationTypes);
  }

  async fetchUserPreferences(recommendationTypes) {
    this.setState({ loading: true });

    const userPreferenceData = await Promise.all(
      recommendationTypes.map(async type => {
        const recommendationTypeKey = type.key;

        const userData = this.context.client.query({
          query: userPreferencesByKeyQuery,
          variables: {
            recommendationTypeKey,
          },
          fetchPolicy: 'network-only',
        });
        return userData;
      }),
    );
    const userPreferences = userPreferenceData.map(preferencesPrev => ({
      recommendationTypeId:
        preferencesPrev.data.userPreferences.recommendationTypeId,
      preferences: {
        popularity: preferencesPrev.data.userPreferences.popularity,
        positiveCorrelation:
          preferencesPrev.data.userPreferences.positiveCorrelation,
        negativeCorrelation:
          preferencesPrev.data.userPreferences.negativeCorrelation,
        age: preferencesPrev.data.userPreferences.age,
        seenCount: preferencesPrev.data.userPreferences.seenCount,
      },
    }));

    if (!userPreferences) {
      this.setState({ loading: false });
    }
    this.setState({
      userPreferences,
      loading: false,
    });
  }

  renderSliders() {
    const {
      recommendationTypesData: { recommendationTypes },
    } = this.props;

    const { userPreferences } = this.state;
    const tabData = recommendationTypes.map(type => {
      const preferencesFiltered = userPreferences.find(
        obj => obj.recommendationTypeId === type.id,
      );

      if (preferencesFiltered) {
        const sliderComponents = Object.entries(
          preferencesFiltered.preferences,
        ).map(preference => (
          <Slider
            interval={INTERVAL}
            value={preference[1] * 100}
            stepSize={1}
            labelFormatter={preference[0]}
            disabled
          />
        ));

        return {
          key: type.key,
          child: [sliderComponents],
        };
      }

      return {
        key: type.key,
        child: [<span>ERROR</span>],
      };
    });
    return tabData;
  }

  render() {
    const {
      recommendationTypesData: { recommendationTypes },
    } = this.props;

    const { loading } = this.state;
    const tabData = this.renderSliders();

    return (
      <div>
        <PreferenceTabs
          defaultActiveKey={recommendationTypes[0].key}
          // eslint-disable-next-line react/no-children-prop
          tab={tabData}
        />
        {loading && <Loading />}
      </div>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  graphql(recommendationTypesQuery, {
    name: 'recommendationTypesData',
  }),
  withStyles(s),
)(UserPreferences);
