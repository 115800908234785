import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage } from 'react-intl';

import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './Imprint.scss';

const messages = defineMessages({
  imprintHeader: {
    id: 'imprint.imprintHeader',
    defaultMessage: 'Imprint',
    description: 'Label for Imprint page header.',
  },
});

class Imprint extends React.Component {
  render() {
    return (
      <div className={s.imprintContainer}>
        <h1>
          <FormattedMessage {...messages.imprintHeader} />
        </h1>

        <div className={s.imprintText}>
          <p>
            3DataX GmbH & Co KG
            <br />
            Limited Partnership
          </p>
          <p>
            Software engineering
            <br />
            Consulting and IT-Services
          </p>
          <p>
            VAT: ATU69357117
            <br />
            Trade register: 430056k
            <br />
            Legal venue: Handelsgericht Wien, Vienna
            <br />
            Office: Lambrechtgasse 3 / 3<br />
            1040 Vienna | Austria
            <br />
            Phone: +43 650 546 50 31
            <br />
            E-Mail: office@3datax.com
            <br />
          </p>
          <p>
            Member of Wirtschaftskammer Wien, Fachverband UBIT
            <br />
            Profession:
            <br />
            {'Trade regulation act: '}
            <a
              href="http://www.ris.bka.gv.at"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.ris.bka.gv.at
            </a>
            <br />
            Magistratisches Bezirksamt für den 4. Bezirk
            <br />
          </p>
          <p>
            Unlimited partner:
            <br />
            3DataX GmbH
            <br />
            Limited liability company
            <br />
            Partner: Klemens Senn (50%), Philipp Wissgott (50%)
            <br />
            Trade register: 428316b
            <br />
            Legal venue: Handelsgericht Wien
            <br />
            Office: Lambrechtgasse 3 / 3<br />
            1040 Vienna | Austria
            <br />
            Manager: Klemens Senn, Philipp Wissgott
            <br />
          </p>
          <p>
            Purpose of the website:
            <br />
            This website contains online services belonging to 3dataX GmbH & Co
            KG.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.danubify.com"
            >
              www.danubify.com
            </a>{' '}
            provides information and services of the company’s products.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=9c11f3a1-856e-4b8f-a167-39fbfda2e928&StandortID=0&Suchbegriff=3datax+gmbh+%26+co+kg&Page=1"
          >
            <img src="http://images.wko.at/common/icons/ecg.gif" alt="" />
          </a>
          <p>
            3DataX GmbH & Co KG, IT-Dienstleistung, 1040 Wien, Lambrechtgasse
            3/3, WKO: Alle Informationen aus erster Hand, FG
            Unternehmensberatung, Buchhaltung und Informationstechn. /
            IT-Dienstleistung
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(Imprint);
