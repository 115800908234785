import { defineMessages } from 'react-intl';

const messages = defineMessages({
  noItemsInList: {
    id: 'genericCardList.noItemsInList',
    defaultMessage: 'No items',
    description: 'label for no items message',
  },
  page: {
    id: 'genericCardList.page',
    defaultMessage: 'Page',
    description: 'label for page message',
  },
});

export default messages;
