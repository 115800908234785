/**
 * Scrolls to the top of a page once its rendered.
 * This is useful for navigation purposes, where you generally want
 * to start on the top of the page you're navigating though.
 *
 * Doesn't jump to the top when hitting the browser's back button,
 * which is desired behaviour since you usually want to end up
 * exactly where you were (including scroll position) if you
 * click back.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const exludedLocations = ['/search'];

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (!exludedLocations.includes(this.props.location.pathname)) {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(ScrollToTop);
