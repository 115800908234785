exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body{color:#fff}._1-eIW{padding-top:30px}._1-eIW p{-webkit-hyphens:auto;-ms-hyphens:auto;hyphens:auto;font-size:20px;margin-top:25px}@media (max-width:480px){._1-eIW p{font-size:15px}}._1-eIW p:first-child{margin-top:0}._1-eIW p:last-child{margin-bottom:45px}._ePqZ{padding:132px 0 50px;margin:0 auto;width:50%}@media (max-width:768px){._ePqZ{padding-top:10px;width:90%}}._ePqZ h1{color:#fff;font-style:normal;font-weight:700;line-height:normal}@media (max-width:768px){._ePqZ h1{font-size:30px}}@media (min-width:768px){._ePqZ h1{font-size:35px}}@media (min-width:992px){._ePqZ h1{font-size:40px}}._ePqZ h2{margin:25px 0 0;font-style:normal;font-weight:700;font-size:25px;line-height:normal}@media (max-width:768px){._ePqZ h2{font-size:20px}}@media (min-width:768px){._ePqZ h2{font-size:20px}}@media (min-width:992px){._ePqZ h2{font-size:25px}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"imprintText": "_1-eIW",
	"imprintContainer": "_ePqZ"
};