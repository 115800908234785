import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';

import { meQuery } from '../../data/queries/userQueries';
import Loading from '../Loading';
import { LOGGED_MODE_USER, LOGGED_MODE_GUEST } from '../../constants';

const AuthenticationWrapper = WrappedComponent => {
  class WithAuthentication extends React.Component {
    static propTypes = {
      meData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        me: PropTypes.shape({
          id: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired,
        }),
      }).isRequired,
    };

    render() {
      const { meData } = this.props;

      if (meData.loading) return <Loading />;

      let loggedMode = null;

      if (meData.me) {
        loggedMode = LOGGED_MODE_USER;
      } else {
        loggedMode = LOGGED_MODE_GUEST;
      }

      return <WrappedComponent loggedMode={loggedMode} {...this.props} />;
    }
  }

  return compose(
    graphql(meQuery, {
      name: 'meData',
    }),
    withRouter,
  )(WithAuthentication);
};

export default AuthenticationWrapper;
