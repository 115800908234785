/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const updateUserPreferencesMutation = gql`
  mutation updateUserPreferences(
    $userPreferences: UserPreferencesUpdateInput!
  ) {
    updateUserPreferences(userPreferences: $userPreferences)
  }
`;
