import { defineMessages } from 'react-intl';

const messages = defineMessages({
  liked: {
    id: 'card.liked',
    defaultMessage: 'Like',
    description: 'label for liked message',
  },
  disliked: {
    id: 'card.disliked',
    defaultMessage: 'Dislike',
    description: 'label for disliked message',
  },
  add: {
    id: 'card.add',
    defaultMessage: 'Add',
    description: 'label for add message',
  },
});

export default messages;
