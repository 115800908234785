/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const getGlobalMetricsQuery = gql`
  query getGlobalMetrics {
    getGlobalMetrics {
      recommendationType
      metric
    }
  }
`;

export const getUserMetricsQuery = gql`
  query getUserMetrics($recommendationTypeKey: String!) {
    getUserMetrics(recommendationTypeKey: $recommendationTypeKey) {
      userMetric
    }
  }
`;
