import React from 'react';

import Registration from 'components/Registration';

class RegistrationWrapper extends React.Component {
  render() {
    return <Registration />;
  }
}

export default RegistrationWrapper;
