import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

import s from './LandingPage.scss';
import messages from './messages';
import withAuthentication from '../Auth/AuthenticationWrapper';
import { recommendationTypesQuery } from '../../data/queries/recommendationTypeQueries';
import Loading from '../Loading';
import CardRecommendationWidget from '../CardRecommendationWidget';
import ShowTitlesCheckbox from '../ShowTitlesCheckbox';
import { getGlobalMetricsQuery } from '../../data/queries/metricsQueries';

class LandingPage extends React.Component {
  static propTypes = {
    loggedMode: PropTypes.string.isRequired,
    recommendationTypesData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      recommendationTypes: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    currentLocale: PropTypes.string.isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.fetchGlobalMetrics = this.fetchGlobalMetrics.bind(this);
    this.renderWidgets = this.renderWidgets.bind(this);
  }

  async fetchGlobalMetrics() {
    // refetch metricsQuery manually (to update globalMetrics numbers in header)
    this.context.client.query({
      query: getGlobalMetricsQuery,
      fetchPolicy: 'network-only',
    });
  }

  renderWidgets() {
    const { loggedMode, recommendationTypesData, currentLocale } = this.props;

    return recommendationTypesData.recommendationTypes.map(
      recommendationType => {
        let { description } = recommendationType;
        if (currentLocale != null && recommendationType.i18n != null) {
          const i18n = recommendationType.i18n.find(
            i => i.language === currentLocale,
          );
          if (i18n) description = i18n.description;
        }

        return (
          <div key={recommendationType.key} className={s.widgetWrapper}>
            <h1>
              <FormattedMessage
                {...messages.recommendationsFor}
                values={{ recommendationType: description }}
              />
            </h1>
            <CardRecommendationWidget
              key={`widget-${recommendationType.key}`}
              loggedMode={loggedMode}
              recommendationTypeKey={recommendationType.key}
              onNextRecommendations={this.fetchGlobalMetrics}
            />
          </div>
        );
      },
    );
  }

  render() {
    const { recommendationTypesData } = this.props;

    if (recommendationTypesData.loading) return <Loading />;

    return (
      <div className={s.landingPageContainer}>
        <div className={s.titleCheckboxContainer}>
          <div className={s.titleCheckbox}>
            <ShowTitlesCheckbox />
          </div>
        </div>
        {this.renderWidgets()}
      </div>
    );
  }
}

const mapState = state => ({
  currentLocale: state.intl.locale,
});

export default compose(
  withAuthentication,
  graphql(recommendationTypesQuery, {
    name: 'recommendationTypesData',
  }),
  connect(mapState),
  withStyles(s),
)(LandingPage);
