import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';
import FaSearch from 'react-icons/lib/fa/search';
import { injectIntl } from 'react-intl';

import { recommendationTitleByKeyQuery } from '../../data/queries/recommendationItemQueries';
import GenericRecommendationItemList from '../GenericRecommendationItemList';
import s from './SearchWidget.scss';
import messages from './messages';

const MIN_LENGTH_SEARCH = 3;
const MAX_SEARCH_ENTRIES = 6;
class SearchWidget extends React.Component {
  static propTypes = {
    recommendationTypeKey: PropTypes.string,
    alwaysShowTitles: PropTypes.bool,
    userDataIsLocked: PropTypes.bool.isRequired,
    userDataAsMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onItemStateChange: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    intl: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static defaultProps = {
    recommendationTypeKey: null,
    alwaysShowTitles: false,
  };

  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
    this.handleSearchClick = this.handleSearchClick.bind(this);

    this.state = {
      value: '',
      items: [],
      itemsToRender: [],
      resetOffsetToggle: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recommendationTypeKey !== this.props.recommendationTypeKey) {
      this.getAllTitles();

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ itemsToRender: [], value: '' });
    }
  }

  async getAllTitles() {
    const { recommendationTypeKey } = this.props;
    const result = await this.context.client.query({
      query: recommendationTitleByKeyQuery,
      variables: {
        recommendationTypeKey,
      },
      fetchPolicy: 'network-only',
    });
    this.setState({
      items: result.data.recommendationItemsByKey.sort((a, b) =>
        a.title > b.title ? 1 : -1,
      ),
    });
  }

  handleSearchClick() {
    const node = this.searchRef.current;
    const autocompleteProps = node.getFilteredItems(node.props);
    if (node.props.value.length >= MIN_LENGTH_SEARCH) {
      this.setState({
        itemsToRender: autocompleteProps.map(props => props.id),
        resetOffsetToggle: !this.state.resetOffsetToggle,
      });
    } else {
      this.setState({
        itemsToRender: [],
        resetOffsetToggle: !this.state.resetOffsetToggle,
      });
    }
  }

  render() {
    const { items, itemsToRender, resetOffsetToggle } = this.state;
    const {
      recommendationTypeKey,
      userDataIsLocked,
      userDataAsMap,
      onItemStateChange,
      alwaysShowTitles,
      intl,
    } = this.props;

    return (
      <>
        <div className={s.searchField}>
          <Autocomplete
            ref={this.searchRef}
            autoHighlight={false}
            items={items}
            shouldItemRender={(item, value) =>
              value.length >= MIN_LENGTH_SEARCH
                ? item.title.toLowerCase().indexOf(value.toLowerCase()) > -1
                : false
            }
            getItemValue={item => item.title}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.id}
                className={s.menuItem}
                style={{
                  background: isHighlighted ? '#404046' : '#202023',
                }}
              >
                {item.title}
              </div>
            )}
            value={this.state.value}
            onChange={char => this.setState({ value: char.target.value })}
            onSelect={(value, item) =>
              this.setState({
                value,
                itemsToRender: [item.id],
                resetOffsetToggle: !this.state.resetOffsetToggle,
              })
            }
            renderInput={params => (
              <input
                {...params}
                type="text"
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.target.blur();
                    this.handleSearchClick();
                  }
                }}
                className={s.searchInput}
                label={recommendationTypeKey}
                placeholder={intl.formatMessage(messages.placeholderSearch)}
              />
            )}
            renderMenu={(menuItems, value, style) => {
              const limitedMenuItems = menuItems.slice(0, MAX_SEARCH_ENTRIES);
              if (limitedMenuItems.length === 0) return <div />;
              return (
                <div
                  className={s.menu}
                  style={{
                    ...style,
                  }}
                >
                  {limitedMenuItems}
                </div>
              );
            }}
          />
          <div className={s.searchButtonWrapper}>
            <button onClick={this.handleSearchClick} className={s.searchButton}>
              <div>
                <FaSearch />
              </div>
            </button>
          </div>
        </div>
        <GenericRecommendationItemList
          recommendationTypeKey={recommendationTypeKey}
          itemIds={itemsToRender}
          userDataIsLocked={userDataIsLocked}
          userDataAsMap={userDataAsMap}
          onItemStateChange={onItemStateChange}
          alwaysShowTitles={alwaysShowTitles}
          resetOffsetToggle={resetOffsetToggle}
        />
      </>
    );
  }
}

export default compose(withStyles(s), injectIntl)(SearchWidget);
