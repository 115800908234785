exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3rjoB{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;position:fixed;top:0;left:0;width:100%;min-height:100%;background:rgba(0,0,0,.5);z-index:9999;overflow:hidden}._2Aph2,._3rjoB span{z-index:10000}._2Aph2{display:block;width:100%}._2Aph2 [class^=Spinner-]{border-top:1.1em solid hsla(0,0%,100%,.5);border-right:1.1em solid hsla(0,0%,100%,.5);border-bottom:1.1em solid hsla(0,0%,100%,.5);border-left:1.1em solid #0db5ff}._3btNp{display:block;width:100%;color:#fff;text-align:center}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"loading": "_3rjoB",
	"spinnerWrapper": "_2Aph2",
	"loadingText": "_3btNp"
};