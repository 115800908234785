import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headerSetPassword: {
    id: 'setPassword.headerSetPassword',
    defaultMessage: 'Set new password',
    description: 'label for set password header',
  },
  inputPassword: {
    id: 'setPassword.inputPassword',
    defaultMessage: 'Password',
    description: 'label for Password field',
  },
  inputPasswordrepeat: {
    id: 'setPassword.inputPasswordrepeat',
    defaultMessage: 'Repeat password',
    description: 'label for repeating Password field',
  },
  submitPassword: {
    id: 'setPassword.submitPassword',
    defaultMessage: 'set password',
    description: 'label for set password submit button',
  },
  notEqualError: {
    id: 'setPassword.notEqualError',
    defaultMessage: 'passwords are not equal',
    description: 'label for not equal password error',
  },
  passwordSetMessage: {
    id: 'setPassword.passwordSetMessage',
    defaultMessage: 'new password is set',
    description: 'label for setting new password',
  },
  passwordErrorLogin: {
    id: 'setPassword.passwordErrorLogin',
    defaultMessage: 'Something went wrong, try again!',
    description: 'label for setting new password',
  },
  somethingWentWrong: {
    id: 'setPassword.somethingWentWrong',
    defaultMessage: 'Invalid token',
    description: 'label for setting new password',
  },
  passwordLengthError: {
    id: 'setPassword.passwordLengthError',
    defaultMessage: 'The password needs to be at least 8 characters long.',
    description: 'label for setting new password',
  },
});

export default messages;
