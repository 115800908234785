import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headerPasswordReset: {
    id: 'resetPassword.headerPasswordReset',
    defaultMessage: 'Reset password',
    description: 'label for password reset header',
  },
  userOrMail: {
    id: 'resetPassword.userOrMail',
    defaultMessage: 'Username or email',
    description: 'label for inputting Username or E-Mail to reset password',
  },
  sendMail: {
    id: 'resetPassword.sendMail',
    defaultMessage: 'send email',
    description: 'label for send mail button',
  },
  sendMailmessage: {
    id: 'resetPassword.sendMailmessage',
    defaultMessage: 'Mail was sent. Please check your mailbox.',
    description: 'label for succesful mail was sent message',
  },
  resetPasswordError: {
    id: 'resetPassword.resetPasswordError',
    defaultMessage: "Oops, that's not a match. Try again?",
    description: 'label for resetPassword error',
  },
});

export default messages;
