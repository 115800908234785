exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2FOyA{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:100%;font-size:22px;padding-top:10vh}@media (max-width:1200px){._2FOyA{font-size:20px}}@media (max-width:992px){._2FOyA{font-size:18px}}@media (max-width:768px){._2FOyA{font-size:16px;padding-top:3vh}}._2FOyA h1{color:#fff}._2zNRc{padding:5px;background:none}._2zNRc,._2zNRc ._1kVp2{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center}._2zNRc ._16mXo{position:relative;margin:0;padding:20px}._2zNRc ._16mXo label{color:#fff}._2zNRc ._16mXo ._33p1l{text-align:center}._2zNRc ._16mXo input{width:100%;border:none;border-bottom:1px solid #fff;background:none;color:#fff}._2zNRc ._16mXo:last-child{margin-bottom:10px}._2zNRc ._2_mch{margin-top:2px;color:#ff5722}._2zNRc ._2KBpX{margin-top:2px;color:#fff}._2zNRc button{margin:10px 0}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"container": "_2FOyA",
	"form": "_2zNRc",
	"formContent": "_1kVp2",
	"formGroup": "_16mXo",
	"inputDiv": "_33p1l",
	"formError": "_2_mch",
	"formMessage": "_2KBpX"
};