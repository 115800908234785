import { defineMessages } from 'react-intl';

const messages = defineMessages({
  showPassword: {
    id: 'toggleSwitch.showPassword',
    defaultMessage: 'show password',
    description: 'label for set password header',
  },
});

export default messages;
