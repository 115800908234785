import React from 'react';

import SetPassword from 'components/SetPassword';

class SetPasswordWrapper extends React.Component {
  render() {
    return <SetPassword />;
  }
}

export default SetPasswordWrapper;
