import React from 'react';

import Login from 'components/Login';

class LoginWrapper extends React.Component {
  render() {
    return <Login />;
  }
}

export default LoginWrapper;
