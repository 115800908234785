import { defineMessages } from 'react-intl';

const messages = defineMessages({
  user: {
    id: 'login.user',
    defaultMessage: 'User',
    description: 'label for user message',
  },
  email: {
    id: 'login.email',
    defaultMessage: 'Email',
    description: 'label for email message',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
    description: 'label for password message',
  },
  repeatPassword: {
    id: 'login.repeatPassword',
    defaultMessage: 'Repeat password',
    description: 'label for repeat password message',
  },
  login: {
    id: 'login.login',
    defaultMessage: 'Login',
    description: 'label for login message',
  },
  register: {
    id: 'login.register',
    defaultMessage: 'Register',
    description: 'label for register message',
  },
  or: {
    id: 'login.or',
    defaultMessage: 'or',
    description: 'label for or message',
  },
  continueAsGuest: {
    id: 'login.continueAsGuest',
    defaultMessage: 'Continue as guest',
    description: 'label for continue as guest message',
  },
  forgotPassword: {
    id: 'login.forgotPassword',
    defaultMessage: 'Forgot password?',
    description: 'label for reseting password',
  },
  required: {
    id: 'login.required',
    defaultMessage: 'This field is required',
    description: 'Validation error message for required fields',
  },
  usernameValidationError: {
    id: 'login.usernameValidationError',
    defaultMessage: 'Your username must be between 4 to 20 characters long.',
    description: 'Validation error message for username',
  },
  emailValidationError: {
    id: 'login.emailValidationError',
    defaultMessage: 'Please provide a valid e-mail address.',
    description: 'Validation error message for email',
  },
  passwordValidationError: {
    id: 'login.passwordValidationError',
    defaultMessage: 'The password needs to be at least 8 characters long.',
    description: 'Validation error message for password',
  },
  passwordConfirmation: {
    id: 'login.passwordConfirmation',
    defaultMessage: 'Passwords do not match',
    description: 'Validation error message for password Confirmation',
  },
  modalTitle: {
    id: 'login.modalTitle',
    defaultMessage: 'Account activated',
    description: 'label for login modal title',
  },
  modalBody: {
    id: 'login.modalBody',
    defaultMessage:
      'Your email has been verified and your account is activated.',
    description: 'label for login modal body',
  },
});

export default messages;
