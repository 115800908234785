/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';

import s from './SetPassword.scss';
import messages from './messages';
import AuthenticationWrapper from '../Auth/AuthenticationWrapper';
import { setPasswordMutation } from '../../data/mutations/userMutations';
import { meQuery } from '../../data/queries/userQueries';
import {
  setUserTokenCookie,
  removeLocalStorageGuestData,
} from '../Login/cookieAndLocalStorage';
import CheckBox from '../CheckBox';
import Loading from '../Loading';
import { LOGGED_MODE_USER } from '../../constants';
import validatePassword from '../../util/validatePassword';

class SetPassword extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    match: PropTypes.object.isRequired,
    loggedMode: PropTypes.string.isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static renderErrorMessage(msg) {
    return <div className={s.formError}>{msg}</div>;
  }

  constructor(props) {
    super(props);

    this.state = {
      setPasswordErrors: [],
      setPasswordMessages: [],
      toggleChecked: false,
      loading: false,
    };
    this.setPassword = this.setPassword.bind(this);
    this.handlesetPassword = this.handlesetPassword.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
  }

  async setPassword(activationCode, password) {
    const response = await this.context.client.mutate({
      mutation: setPasswordMutation,
      variables: {
        password,
        activationCode,
      },
    });
    return response;
  }

  async handlesetPassword(values, { setSubmitting }) {
    const { password, passwordrepeat } = values;
    const {
      match: {
        params: { code },
      },
    } = this.props;
    const activationCode = code;

    setSubmitting(true);
    this.setState({
      setPasswordErrors: [],
      setPasswordMessages: [],
      loading: true,
    });
    const passwordValidation = validatePassword(password);

    if (!passwordValidation.valid) {
      this.setState({
        setPasswordErrors: [messages.passwordLengthError],
        loading: false,
      });
      setSubmitting(false);
    } else if (password !== passwordrepeat) {
      this.setState({
        setPasswordErrors: [messages.notEqualError],
        loading: false,
      });
      setSubmitting(false);
    } else {
      const result = await this.setPassword(activationCode, password);

      if (!result) {
        this.setState({
          setPasswordErrors: [messages.passwordErrorLogin],
          loading: false,
        });
        setSubmitting(false);
      } else if (
        result &&
        result.data &&
        result.data.setPassword?.token &&
        result.data.setPassword?.success
      ) {
        setUserTokenCookie(result.data.setPassword.token);
        removeLocalStorageGuestData();

        // refetch meQuery manually
        await this.context.client.query({
          query: meQuery,
          fetchPolicy: 'network-only',
        });

        this.setState({
          setPasswordMessages: [messages.passwordSetMessage],
          loading: false,
        });
        window.location = '/';
      } else {
        this.setState({
          setPasswordErrors: [messages.somethingWentWrong],
          loading: false,
        });
        setSubmitting(false);
      }
    }
  }

  handleShowPassword() {
    this.setState(prevState => ({
      toggleChecked: !prevState.toggleChecked,
    }));
  }

  render() {
    const {
      setPasswordErrors,
      setPasswordMessages,
      toggleChecked,
      loading,
    } = this.state;
    const { loggedMode } = this.props;

    if (loggedMode === LOGGED_MODE_USER) {
      return <Redirect to="/" />;
    }

    return (
      <div className={s.container}>
        <div>
          <h1>
            <FormattedMessage {...messages.headerSetPassword} />
          </h1>
        </div>
        <Formik
          initialValues={{ password: '', passwordrepeat: '' }}
          validate={values => {
            const errors = {};
            if (!values.password) {
              errors.password = 'Required';
              this.setState({ setPasswordErrors: [] });
            }
            if (!values.passwordrepeat) {
              errors.passwordrepeat = 'Required';
              this.setState({ setPasswordErrors: [] });
            }
            return errors;
          }}
          onSubmit={this.handlesetPassword}
        >
          {({ isSubmitting }) => (
            <Form className={s.form}>
              <div className={s.formContent}>
                <fieldset>
                  <div className={s.formGroup}>
                    <label htmlFor="password">
                      <FormattedMessage {...messages.inputPassword} />
                    </label>
                    <Field
                      type={toggleChecked ? 'text' : 'password'}
                      id="password"
                      name="password"
                    />
                    <ErrorMessage
                      name="password"
                      render={SetPassword.renderErrorMessage}
                    />
                  </div>
                  <div className={s.formGroup}>
                    <label htmlFor="password">
                      <FormattedMessage {...messages.inputPasswordrepeat} />
                    </label>
                    <Field
                      type={toggleChecked ? 'text' : 'password'}
                      id="passwordrepeat"
                      name="passwordrepeat"
                    />
                    <ErrorMessage
                      name="passwordrepeat"
                      render={SetPassword.renderErrorMessage}
                    />
                  </div>
                </fieldset>
                {setPasswordErrors.length > 0 && (
                  <div>
                    {setPasswordErrors.map(error => (
                      <div key={error.id} className={s.formError}>
                        <FormattedMessage {...error} />
                      </div>
                    ))}
                  </div>
                )}
                {setPasswordMessages.length > 0 && (
                  <div>
                    {setPasswordMessages.map(message => (
                      <div key={message.id} className={s.formMessage}>
                        <FormattedMessage {...message} />
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-ur btn-round btn-wide"
                  disabled={isSubmitting}
                >
                  <FormattedMessage {...messages.submitPassword} />
                </button>
                <CheckBox
                  id="passwordshow"
                  checked={toggleChecked}
                  onChange={this.handleShowPassword}
                />
              </div>
            </Form>
          )}
        </Formik>
        {loading && <Loading />}
      </div>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  withRouter,
  withStyles(s),
)(SetPassword);
