import { defineMessages } from 'react-intl';

const messages = defineMessages({
  recommendations: {
    id: 'recommendationTypePage.recommendations',
    defaultMessage: 'Recommendations',
    description: 'label for recommendations message',
  },
});

export default messages;
