/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';

// eslint-disable-next-line css-modules/no-unused-class
import s from '../Login/Login.scss';
import Loading from '../Loading';
import withAuthentication from '../Auth/AuthenticationWrapper';
import loginMessages from '../Login/messages';
import messages from './messages';
import validatePassword from '../../util/validatePassword';
import validateUsername from '../../util/validateUsername';
import { LOGGED_MODE_USER } from '../../constants';
import validateEmail from '../../util/validateEmail';
import withRegister from './withRegister';
import Modal from '../Modal';

class Registration extends React.Component {
  static propTypes = {
    loggedMode: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static renderErrorMessage(msg) {
    return <div className={s.formError}>{msg}</div>;
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      registrationErrors: [],
      showRegisterModal: false,
      modalEmail: null,
    };

    this.handleRegister = this.handleRegister.bind(this);
  }

  async handleRegister(values, { setSubmitting, resetForm }) {
    const usernameValidation = validateUsername(values.username);
    if (!usernameValidation.valid) {
      this.setState({
        registrationErrors: [usernameValidation.error],
      });
      return;
    }

    const emailValidation = validateEmail(values.email);
    if (!emailValidation.valid) {
      this.setState({
        registrationErrors: [emailValidation.error],
      });
      return;
    }

    const passwordValidation = validatePassword(values.password);
    if (!passwordValidation.valid) {
      this.setState({
        registrationErrors: [passwordValidation.error],
      });
      return;
    }

    if (values.password !== values.repeatPassword) {
      this.setState({
        registrationErrors: [
          <FormattedMessage {...loginMessages.passwordConfirmation} />,
        ],
      });
      return;
    }

    const { register } = this.props;
    setSubmitting(true);

    this.setState({ loading: true });

    try {
      const result = await register({
        username: values.username,
        password: values.password,
        email: values.email,
      });
      if (!result) {
        this.setState({
          loading: false,
          registrationErrors: ['Invalid credentials.'],
        });
        setSubmitting(false);
      } else {
        this.setState({
          loading: false,
          registrationErrors: [],
          showRegisterModal: true,
          modalEmail: values.email,
        });
        setSubmitting(false);
        resetForm();
      }
    } catch (e) {
      this.setState({
        loading: false,
        registrationErrors: [e.message],
      });
      setSubmitting(false);
    }
  }

  render() {
    const { loggedMode } = this.props;
    const {
      loading,
      registrationErrors,
      showRegisterModal,
      modalEmail,
    } = this.state;

    if (loggedMode === LOGGED_MODE_USER) {
      return <Redirect to="/" />;
    }

    return (
      <div className={s.container}>
        <Formik
          initialValues={{
            username: '',
            email: '',
            password: '',
            repeatPassword: '',
          }}
          validate={values => {
            const errors = {};
            if (!values.username) {
              errors.username = (
                <FormattedMessage {...loginMessages.required} />
              );
            }
            if (!values.email) {
              errors.email = <FormattedMessage {...loginMessages.required} />;
            }
            if (!values.password) {
              errors.password = (
                <FormattedMessage {...loginMessages.required} />
              );
            }
            if (!values.repeatPassword) {
              errors.repeatPassword = (
                <FormattedMessage {...loginMessages.required} />
              );
            }
            return errors;
          }}
          onSubmit={this.handleRegister}
        >
          {({ isSubmitting }) => (
            <Form className={s.form}>
              <div className={s.formContent}>
                <fieldset>
                  <div className={s.formGroup}>
                    <label htmlFor="username">
                      <FormattedMessage {...loginMessages.user} />
                    </label>
                    <Field type="text" id="username" name="username" />
                    <ErrorMessage
                      name="username"
                      render={Registration.renderErrorMessage}
                    />
                  </div>
                  <div className={s.formGroup}>
                    <label htmlFor="email">
                      <FormattedMessage {...loginMessages.email} />
                    </label>
                    <Field type="text" id="email" name="email" />
                    <ErrorMessage
                      name="email"
                      render={Registration.renderErrorMessage}
                    />
                  </div>
                  <div className={s.formGroup}>
                    <label htmlFor="password">
                      <FormattedMessage {...loginMessages.password} />
                    </label>
                    <Field type="password" id="password" name="password" />
                    <ErrorMessage
                      name="password"
                      render={Registration.renderErrorMessage}
                    />
                  </div>
                  <div className={s.formGroup}>
                    <label htmlFor="repeatPassword">
                      <FormattedMessage {...loginMessages.repeatPassword} />
                    </label>
                    <Field
                      type="password"
                      id="repeatPassword"
                      name="repeatPassword"
                    />
                    <ErrorMessage
                      name="repeatPassword"
                      render={Registration.renderErrorMessage}
                    />
                  </div>
                </fieldset>
                {registrationErrors.length > 0 && (
                  <div>
                    {registrationErrors.map(error => (
                      <div key={error} className={s.formError}>
                        {error}
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-ur btn-round"
                  disabled={isSubmitting}
                >
                  <FormattedMessage {...loginMessages.register} />
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <Modal
          show={showRegisterModal}
          title={
            <div style={{ width: '100%', textAlign: 'center' }}>
              <h1>
                <FormattedMessage {...messages.modalTitle} />
              </h1>
            </div>
          }
          body={
            <p style={{ textAlign: 'center' }}>
              <FormattedMessage
                {...messages.modalBody}
                values={{ email: modalEmail }}
              />
            </p>
          }
          hide={() => {
            this.setState({ showRegisterModal: false });
          }}
        />
        {loading && <Loading />}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withRegister,
  withRouter,
  withStyles(s),
)(Registration);
