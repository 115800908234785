/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const calculateGuestBenchmarkQuery = gql`
  query calculateGuestBenchmark($guestBenchmarkData: GuestBenchmarkData!) {
    calculateGuestBenchmark(guestBenchmarkData: $guestBenchmarkData) {
      benchmarkData
      dampFactor
    }
  }
`;
