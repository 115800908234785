import React from 'react';
import PropTypes from 'prop-types';

import { registerMutation } from '../../data/mutations/userMutations';
import { getLocalStorageGuestData } from '../Login/cookieAndLocalStorage';

function withRegister(WrappedComponent) {
  class RegisterWrapper extends React.Component {
    static propTypes = {
      location: PropTypes.shape({
        search: PropTypes.string,
      }).isRequired,
    };

    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);

      this.register = this.register.bind(this);
    }

    async register({ username, password, email }) {
      const response = await this.context.client.mutate({
        mutation: registerMutation,
        variables: {
          username,
          password,
          email,
          guestData: JSON.stringify(getLocalStorageGuestData()),
        },
      });

      if (
        response &&
        response.data &&
        response.data.register &&
        response.data.register.success
      ) {
        return true;
      }
      return false;
    }

    render() {
      return <WrappedComponent register={this.register} {...this.props} />;
    }
  }

  return RegisterWrapper;
}

export default withRegister;
