import React from 'react';

import RecommendationTypePage from 'components/RecommendationTypePage';

class RecommendationTypePageWrapper extends React.Component {
  render() {
    return <RecommendationTypePage />;
  }
}

export default RecommendationTypePageWrapper;
