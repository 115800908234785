import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Spinner.scss';

class Spinner extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    margin: PropTypes.string,
    fontSize: PropTypes.string,
  };

  static defaultProps = {
    margin: '60px auto',
    fontSize: '10px',
  };

  render() {
    return this.props.show ? (
      <div
        className={s.loader}
        style={{ margin: this.props.margin, fontSize: this.props.fontSize }}
      />
    ) : null;
  }
}

export default withStyles(s)(Spinner);
