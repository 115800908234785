exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2nhzB{width:100%;min-height:150px;background-color:#000;color:#fff;padding:30px 100px}@media (max-width:992px){._2nhzB{padding:30px 75px}}@media (max-width:768px){._2nhzB{padding:30px}}._2nhzB ._334if{height:100%;display:-ms-flexbox;display:flex;-ms-flex-flow:row wrap;flex-flow:row wrap;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:start;align-items:flex-start}@media (max-width:768px){._2nhzB ._334if{-ms-flex-flow:column nowrap;flex-flow:column nowrap;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:start;align-items:flex-start}}._2nhzB ._334if ._1jbmC,._2nhzB ._334if ._327to{display:-ms-flexbox;display:flex;-ms-flex-flow:row wrap;flex-flow:row wrap;-ms-flex-pack:justify;justify-content:space-between}@media (max-width:992px){._2nhzB ._334if ._1jbmC,._2nhzB ._334if ._327to{-ms-flex-flow:column nowrap;flex-flow:column nowrap;padding-bottom:20px}}._2nhzB ._334if ._1jbmC a,._2nhzB ._334if ._327to a{color:#fff;text-decoration:none}._2nhzB ._334if ._1jbmC a:hover,._2nhzB ._334if ._327to a:hover{background-image:-webkit-gradient(linear,left top,right top,from(#fd5061),to(#6a70fe));background-image:-webkit-linear-gradient(left,#fd5061,#6a70fe);background-image:-o-linear-gradient(left,#fd5061,#6a70fe);background-image:linear-gradient(90deg,#fd5061,#6a70fe);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:transparent}._2nhzB ._334if ._1jbmC ._12yR_,._2nhzB ._334if ._327to ._12yR_{padding:5px 10px}@media (max-width:1200px){._2nhzB ._334if ._1jbmC ._12yR_,._2nhzB ._334if ._327to ._12yR_{padding:4px 10px}}@media (max-width:992px){._2nhzB ._334if ._1jbmC ._12yR_,._2nhzB ._334if ._327to ._12yR_{padding:3px 10px}}@media (max-width:768px){._2nhzB ._334if ._1jbmC ._12yR_,._2nhzB ._334if ._327to ._12yR_{padding:2px 10px}}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"footer": "_2nhzB",
	"footerContentWrapper": "_334if",
	"footerLeftItems": "_1jbmC",
	"footerRightItems": "_327to",
	"footerItemWrapper": "_12yR_"
};