import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// eslint-disable-next-line css-modules/no-unused-class
import s from './Card.scss';

class EmptyCard extends React.Component {
  render() {
    return <div className={`${s.emptyCard}`} />;
  }
}

export default withStyles(s)(EmptyCard);
