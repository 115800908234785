import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'modal.close',
    defaultMessage: 'Close',
    description: 'default modal close button label',
  },
});

export default messages;
