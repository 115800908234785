const parseGetParameters = searchUrl => {
  let parsedUrl = searchUrl;
  if (searchUrl.startsWith('?')) {
    parsedUrl = parsedUrl.replace('?', '');
  }
  const params = parsedUrl.split(/[&|?]/);
  const paramsObject = {};
  params.forEach(param => {
    const splitParam = param.split('=');
    const key = splitParam[0];
    const val = splitParam[1];
    paramsObject[key] = val;
  });
  return paramsObject;
};

// eslint-disable-next-line import/prefer-default-export
export { parseGetParameters };
