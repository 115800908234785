exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#app,.container-fluid,body,html{min-height:100%!important;margin:0;padding:0;background-image:-webkit-gradient(linear,left top,right top,from(#250101),to(#010938));background-image:-webkit-linear-gradient(left,#250101,#010938);background-image:-o-linear-gradient(left,#250101,#010938);background-image:linear-gradient(90deg,#250101,#010938)}body,html{margin:0}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"app": "app",
	"container-fluid": "container-fluid"
};