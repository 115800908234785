exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FL5lV{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:100%;font-size:22px;padding-top:10vh}@media (max-width:1200px){.FL5lV{font-size:20px}}@media (max-width:992px){.FL5lV{font-size:18px}}@media (max-width:768px){.FL5lV{font-size:16px;padding-top:3vh}}._2o325{padding:5px;background:none}._2o325,._2o325 ._3iZyi{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center}._2o325 ._1KVa0{position:relative;margin:0;padding:20px}._2o325 ._1KVa0 label{color:#fff}._2o325 ._1KVa0 input{width:100%;border:none;border-bottom:1px solid #fff;background:none;color:#fff}._2o325 ._1KVa0:last-child{margin-bottom:10px}._2o325 .N9Vz4{margin-top:2px;color:#ff5722}._2o325 button{margin:10px 0}._2o325 ._3M6ac{text-align:center}._2o325 .Ki6bA{padding-top:15px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"container": "FL5lV",
	"form": "_2o325",
	"formContent": "_3iZyi",
	"formGroup": "_1KVa0",
	"formError": "N9Vz4",
	"parentLinkDiv": "_3M6ac",
	"childLinkDiv": "Ki6bA"
};