import React from 'react';
import PropTypes from 'prop-types';

import { loginQuery, meQuery } from '../../data/queries/userQueries';
import {
  setUserTokenCookie,
  removeLocalStorageGuestData,
} from './cookieAndLocalStorage';

function withLogin(WrappedComponent) {
  class LoginWrapper extends React.Component {
    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);

      this.login = this.login.bind(this);
      this.loginViaToken = this.loginViaToken.bind(this);
    }

    async login({ username, password }) {
      const response = await this.context.client.query({
        query: loginQuery,
        variables: {
          username,
          password,
        },
        fetchPolicy: 'network-only',
      });
      if (
        response &&
        response.data &&
        response.data.login &&
        response.data.login.success
      ) {
        await this.loginViaToken({ token: response.data.login.token });
        return true;
      }
      return false;
    }

    async loginViaToken({ token }) {
      setUserTokenCookie(token);
      removeLocalStorageGuestData();

      // refetch meQuery manually
      await this.context.client.query({
        query: meQuery,
        fetchPolicy: 'network-only',
      });
    }

    render() {
      return (
        <WrappedComponent
          login={this.login}
          loginViaToken={this.loginViaToken}
          {...this.props}
        />
      );
    }
  }

  return LoginWrapper;
}

export default withLogin;
