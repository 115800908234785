/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { compose } from 'react-apollo';

import s from './CheckBox.scss';
import messages from './messages';

class ToggleSwitch extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  render() {
    const { id, checked, onChange } = this.props;

    return (
      <div className={s.titleCheckboxContainer}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={e => onChange(e.target.checked)}
        />
        <label>
          <FormattedMessage {...messages.showPassword} />
        </label>
      </div>
    );
  }
}

export default compose(withStyles(s))(ToggleSwitch);
