exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39jVq .nGPrw{margin:0;padding:20px;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%)!important;-ms-transform:translate(-50%,-50%)!important;transform:translate(-50%,-50%)!important;width:400px;max-width:calc(100% - 30px);border-radius:10px;border:2px solid #95a7a7;background-color:#141414}._39jVq .nGPrw>div{background:none;border:none;-webkit-box-shadow:none;box-shadow:none}._39jVq ._2Md7Z{font-weight:900;padding-top:10px;padding-bottom:20px;text-transform:uppercase}._39jVq ._2Md7Z h1{color:#fff;font-size:26px}._39jVq .ZVJon{font-size:20px;padding-top:30px;font-weight:300;color:#fff}._39jVq ._3omCO{text-align:center;padding-top:20px;padding-bottom:0}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"defaultAlert": "_39jVq",
	"defaultDialog": "nGPrw",
	"defaultHeader": "_2Md7Z",
	"defaultBody": "ZVJon",
	"defaultFooter": "_3omCO"
};