import Login from './login';
import Registration from './registration';
import LandingPage from './landingPage';
import RecommendationTypePage from './recommendationTypePage';
import Imprint from './imprint';
import ResetPassword from './resetPassword';
import SetPassword from './setPassword';
import UserPreferences from '../components/UserPreferences';

const routes = [
  {
    path: '/',
    component: LandingPage,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/register',
    component: Registration,
    exact: true,
  },
  {
    path: '/recommendations/:recommendationTypeKey',
    component: RecommendationTypePage,
    exact: true,
  },
  {
    path: '/imprint',
    component: Imprint,
    exact: true,
  },
  {
    path: '/resetpassword',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/setpassword/token/:code',
    component: SetPassword,
  },
  {
    path: '/profile',
    component: UserPreferences,
    exact: true,
  },
];

export default routes;
