import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';
import s from './Layout.scss';
import TopNav from '../TopNav';
import Footer from '../Footer';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <Container fluid style={{ height: '100%' }}>
        <TopNav />
        <div className={s.layoutContent}>{this.props.children}</div>
        <Footer />
      </Container>
    );
  }
}

export default withStyles(baseStyles, s)(Layout);
