exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2WqV2{padding:10px;color:#fff}._2WqV2 label{margin-left:10px;font-size:18px}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"titleCheckboxContainer": "_2WqV2"
};