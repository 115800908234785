import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'register.modalTitle',
    defaultMessage: 'Verify e-mail',
    description: 'label for register modal title',
  },
  modalBody: {
    id: 'register.modalBody',
    defaultMessage:
      'An email has been sent to {email}. Click on the link in the email to complete your registration.',
    description: 'label for register modal body',
  },
});

export default messages;
