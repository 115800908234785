import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaUser from 'react-icons/lib/fa/user';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './TopNav.scss';
import withLogout from '../Login/withLogout';
import { meQuery } from '../../data/queries/userQueries';
import { recommendationTypesQuery } from '../../data/queries/recommendationTypeQueries';
import { getGlobalMetricsQuery } from '../../data/queries/metricsQueries';
import LanguageSwitcher from '../LanguageSwitcher';
import messages from './messages';
import { isServerSide } from '../../util';

const MenuItem = NavDropdown.Item;

class TopNav extends React.Component {
  static propTypes = {
    meData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }),
    }).isRequired,
    recommendationTypesData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      recommendationTypes: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    globalMetricsData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getGlobalMetrics: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    logout: PropTypes.func.isRequired,
    currentLocale: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.handleUserDropdownSelect = this.handleUserDropdownSelect.bind(this);
  }

  handleUserDropdownSelect(e) {
    if (e === 'logout') {
      this.props.logout();
    } else if (e === 'login') {
      this.props.history.push('/login');
    } else if (e === 'register') {
      this.props.history.push('/register');
    }
  }

  render() {
    const {
      meData,
      recommendationTypesData,
      globalMetricsData,
      currentLocale,
    } = this.props;

    let userName = null;
    const userDropdownItems = [];

    if (isServerSide() || meData.loading) {
      // do nothing
    } else if (!meData.loading && meData.me) {
      userName = meData.me.username;
      userDropdownItems.push(
        <MenuItem key="logout" eventKey="logout">
          <FormattedMessage {...messages.logout} />
        </MenuItem>,
      );
    } else {
      userName = <FormattedMessage {...messages.guest} />;
      userDropdownItems.push(
        <MenuItem key="login" eventKey="login">
          <FormattedMessage {...messages.login} />
        </MenuItem>,
      );
      userDropdownItems.push(
        <MenuItem key="register" eventKey="register">
          <FormattedMessage {...messages.register} />
        </MenuItem>,
      );
    }

    return (
      <Navbar collapseOnSelect expand="lg" className={s.topNavContainer}>
        <Navbar.Brand className={s.logoContainer}>
          <Link className={s.logoLink} to="/">
            <div className={s.logoNameFull}>UNIVERSAL RECOMMENDER</div>
            <div className={s.logoNameInitials}>UR</div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          className={s.navbarToggle}
          aria-controls="responsive-navbar-nav"
        />
        <Navbar.Collapse
          className={s.navbarCollapse}
          id="responsive-navbar-nav"
        >
          <Nav className={`mr-auto ${s.nav}`}>
            {!recommendationTypesData.loading &&
              recommendationTypesData.recommendationTypes &&
              recommendationTypesData.recommendationTypes.map(
                recommendationType => {
                  let className = s.navLink;
                  if (
                    this.props.location.pathname ===
                    `/recommendations/${recommendationType.key}`
                  ) {
                    className = `${className} ${s.navLinkActive}`;
                  }

                  let { description } = recommendationType;
                  if (
                    currentLocale != null &&
                    recommendationType.i18n != null
                  ) {
                    const i18n = recommendationType.i18n.find(
                      i => i.language === currentLocale,
                    );
                    if (i18n) description = i18n.description;
                  }

                  const globalMetrics = globalMetricsData.getGlobalMetrics.find(
                    e => e.recommendationType === recommendationType.key,
                  );

                  return (
                    <NavItem key={recommendationType.key} className={s.navItem}>
                      <Link
                        to={`/recommendations/${recommendationType.key}`}
                        className={className}
                      >
                        {description} ({globalMetrics.metric}%)
                      </Link>
                    </NavItem>
                  );
                },
              )}
          </Nav>
          <Nav className={`ml-auto ${s.nav}`}>
            {userName != null && (
              <Nav onSelect={this.handleUserDropdownSelect}>
                <NavDropdown
                  className={s.navDropdown}
                  title={
                    <span>
                      <FaUser /> {userName}
                    </span>
                  }
                  alignRight
                >
                  {userDropdownItems}
                </NavDropdown>
              </Nav>
            )}
            <LanguageSwitcher dropDownClassName={s.languageDropdown} />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

TopNav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const mapState = state => ({
  currentLocale: state.intl.locale,
});

export default compose(
  graphql(meQuery, {
    name: 'meData',
  }),
  graphql(recommendationTypesQuery, {
    name: 'recommendationTypesData',
  }),
  graphql(getGlobalMetricsQuery, {
    name: 'globalMetricsData',
  }),
  connect(mapState),
  withRouter,
  withLogout,
  withStyles(s),
)(TopNav);
