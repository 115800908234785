import { defineMessages } from 'react-intl';

const messages = defineMessages({
  recommendationsFor: {
    id: 'landingPage.recommendationsFor',
    defaultMessage: '{recommendationType} - Recommendations',
    description: 'label for recommendations message',
  },
});

export default messages;
