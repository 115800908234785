import { defineMessages } from 'react-intl';

const messages = defineMessages({
  about: {
    id: 'footer.about',
    defaultMessage: 'About',
    description: 'label for about message',
  },
  contactUs: {
    id: 'footer.contactUs',
    defaultMessage: 'Contact Us',
    description: 'label for contact us message',
  },
  privacy: {
    id: 'footer.privacy',
    defaultMessage: 'Privacy Policy',
    description: 'label for privacy policy message',
  },
  terms: {
    id: 'footer.terms',
    defaultMessage: 'Terms of Use',
    description: 'label for terms of use message',
  },
  imprint: {
    id: 'footer.imprint',
    defaultMessage: 'Imprint',
    description: 'label for imprint message',
  },
});

export default messages;
