import React from 'react';
import { PropTypes, oneOfType } from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Modal.scss';
import messages from './messages';

class AlertComp extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    title: PropTypes.node,
    body: PropTypes.node,
    footer: PropTypes.node,
    footerButtonMsg: PropTypes.string,
    backdrop: oneOfType([PropTypes.string, PropTypes.bool]),
    hide: PropTypes.func,
    className: PropTypes.string,
    dialogClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
  };

  static defaultProps = {
    show: false,
    title: null,
    body: null,
    footer: null,
    footerButtonMsg: null,
    backdrop: true,
    hide: () => {},
    className: s.defaultAlert,
    dialogClassName: s.defaultDialog,
    headerClassName: s.defaultHeader,
    bodyClassName: s.defaultBody,
    footerClassName: s.defaultFooter,
  };

  render() {
    const {
      show,
      title,
      body,
      footer,
      footerButtonMsg,
      backdrop,
      hide,
      className,
      dialogClassName,
      headerClassName,
      bodyClassName,
      footerClassName,
    } = this.props;

    return (
      <Modal
        show={show}
        className={className}
        dialogClassName={dialogClassName}
        backdrop={backdrop}
        onHide={hide}
        enforceFocus={false}
      >
        <Modal.Header className={headerClassName}>
          {title || <h1>Header</h1>}
        </Modal.Header>
        <Modal.Body className={bodyClassName}>{body || <p>...</p>}</Modal.Body>
        <Modal.Footer className={footerClassName}>
          {footer || (
            <button type="button" className="btn-ur btn-round" onClick={hide}>
              {footerButtonMsg || <FormattedMessage {...messages.modalTitle} />}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withStyles(s)(AlertComp);
