import { defineMessages } from 'react-intl';

const messages = defineMessages({
  logout: {
    id: 'topNav.logout',
    defaultMessage: 'Logout',
    description: 'label for logout message',
  },
  login: {
    id: 'topNav.login',
    defaultMessage: 'Login',
    description: 'label for login message',
  },
  register: {
    id: 'topNav.register',
    defaultMessage: 'Register',
    description: 'label for register message',
  },
  guest: {
    id: 'topNav.guest',
    defaultMessage: 'GUEST',
    description: 'label for guest message',
  },
});

export default messages;
