/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const userPreferencesByKeyQuery = gql`
  query userPreferences($recommendationTypeKey: String!) {
    userPreferences(recommendationTypeKey: $recommendationTypeKey) {
      id
      recommendationTypeId
      popularity
      positiveCorrelation
      negativeCorrelation
      age
      seenCount
    }
  }
`;

export const getNewGuestPreferencesQuery = gql`
  query getNewGuestPreferences($userPreferences: UserPreferencesUpdateInput!) {
    getNewGuestPreferences(userPreferences: $userPreferences) {
      id
      popularity
      positiveCorrelation
      negativeCorrelation
      age
      seenCount
    }
  }
`;
