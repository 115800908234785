import { defineMessages } from 'react-intl';

const messages = defineMessages({
  placeholderSearch: {
    id: 'searchWidget.placeholderSearch',
    defaultMessage: 'Type to search',
    description: 'label for search placeholder message',
  },
});

export default messages;
