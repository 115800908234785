import React from 'react';

import Imprint from 'components/Imprint';

class ImprintWrapper extends React.Component {
  render() {
    return <Imprint />;
  }
}

export default ImprintWrapper;
