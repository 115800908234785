import React from 'react';

class NotFound extends React.Component {
  render() {
    return (
      <div
        style={{
          fontSize: 40,
          color: 'white',
          textAlign: 'center',
          marginTop: '100px',
        }}
      >
        404
      </div>
    );
  }
}

export default NotFound;
