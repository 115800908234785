import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import FaCaretLeft from 'react-icons/lib/fa/caret-left';
import FaCaretRight from 'react-icons/lib/fa/caret-right';
// import FaPlus from 'react-icons/lib/fa/plus';

import s from './GenericRecommendationItemList.scss';
import messages from './messages';
import Card from '../Card';
import SkeletonCard from '../Card/SkeletonCard';
import EmptyCard from '../Card/EmptyCard';
import { recommendationItemsByIdsQuery } from '../../data/queries/recommendationItemQueries';
import { NUMBER_OF_LIST_ITEMS, ITEM_RESET } from '../../constants';

class GenericRecommendationItemList extends React.Component {
  static propTypes = {
    recommendationTypeKey: PropTypes.string,
    itemIds: PropTypes.arrayOf(PropTypes.string).isRequired, // eslint-disable-line react/no-unused-prop-types
    userDataIsLocked: PropTypes.bool.isRequired,
    userDataAsMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onItemStateChange: PropTypes.func.isRequired,
    itemsRemovable: PropTypes.bool,
    alwaysShowTitles: PropTypes.bool,
    resetOffsetToggle: PropTypes.bool,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static defaultProps = {
    recommendationTypeKey: null,
    itemsRemovable: false,
    alwaysShowTitles: false,
    resetOffsetToggle: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      recommendationItems: null,
      totalNumberOfItems: 0,
      queryOffset: 0,
    };

    this.reloadList = this.reloadList.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.renderCards = this.renderCards.bind(this);
  }

  componentDidMount() {
    this.reloadList(true);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.recommendationTypeKey !== this.props.recommendationTypeKey ||
      prevProps.itemIds !== this.props.itemIds ||
      prevProps.resetOffsetToggle !== this.props.resetOffsetToggle
    ) {
      this.reloadList(
        prevProps.recommendationTypeKey !== this.props.recommendationTypeKey ||
          prevProps.resetOffsetToggle !== this.props.resetOffsetToggle,
      );
    }
  }

  async reloadList(resetOffset) {
    this.setState({
      loading: true,
    });

    const { itemIds } = this.props;
    const { queryOffset } = this.state;

    let newQueryOffset = resetOffset ? 0 : queryOffset;

    if (itemIds.length <= newQueryOffset) {
      newQueryOffset = Math.max(newQueryOffset - NUMBER_OF_LIST_ITEMS, 0);
    }

    const result = await this.context.client.query({
      query: recommendationItemsByIdsQuery,
      variables: {
        ids: itemIds,
        limit: NUMBER_OF_LIST_ITEMS + 1,
        offset: newQueryOffset,
      },
      fetchPolicy: 'network-only',
    });

    this.setState({
      loading: false,
      recommendationItems:
        result.data.recommendationItemsByIds.recommendationItems,
      totalNumberOfItems: result.data.recommendationItemsByIds.count,
      queryOffset: newQueryOffset,
    });
  }

  async nextPage() {
    this.setState({
      loading: true,
    });

    const { itemIds } = this.props;
    const { recommendationItems, queryOffset } = this.state;

    if (recommendationItems.length <= NUMBER_OF_LIST_ITEMS) return;

    const newQueryOffset = queryOffset + NUMBER_OF_LIST_ITEMS;

    const result = await this.context.client.query({
      query: recommendationItemsByIdsQuery,
      variables: {
        ids: itemIds,
        limit: NUMBER_OF_LIST_ITEMS + 1,
        offset: newQueryOffset,
      },
      fetchPolicy: 'network-only',
    });

    this.setState({
      loading: false,
      recommendationItems:
        result.data.recommendationItemsByIds.recommendationItems,
      totalNumberOfItems: result.data.recommendationItemsByIds.count,
      queryOffset: newQueryOffset,
    });
  }

  async prevPage() {
    this.setState({
      loading: true,
    });

    const { itemIds } = this.props;
    const { queryOffset } = this.state;

    if (queryOffset === 0) return;

    const newQueryOffset = Math.max(queryOffset - NUMBER_OF_LIST_ITEMS, 0);

    const result = await this.context.client.query({
      query: recommendationItemsByIdsQuery,
      variables: {
        ids: itemIds,
        limit: NUMBER_OF_LIST_ITEMS + 1,
        offset: newQueryOffset,
      },
      fetchPolicy: 'network-only',
    });

    this.setState({
      loading: false,
      recommendationItems:
        result.data.recommendationItemsByIds.recommendationItems,
      totalNumberOfItems: result.data.recommendationItemsByIds.count,
      queryOffset: newQueryOffset,
    });
  }

  renderCards() {
    const {
      userDataIsLocked,
      userDataAsMap,
      onItemStateChange,
      itemsRemovable,
      alwaysShowTitles,
    } = this.props;
    const { recommendationItems, loading } = this.state;

    if (!recommendationItems) {
      const skeletonCards = [];
      for (let i = 0; i < NUMBER_OF_LIST_ITEMS; i += 1) {
        skeletonCards.push(
          <div className={s.cardWrapper} key={`card-${i}`}>
            <SkeletonCard />
          </div>,
        );
      }
      return skeletonCards;
    }

    if (recommendationItems.length === 0) {
      const emptyCards = [];
      for (let i = 0; i < NUMBER_OF_LIST_ITEMS; i += 1) {
        emptyCards.push(
          <div className={s.cardWrapper} key={`card-${i}`}>
            <EmptyCard />
          </div>,
        );
      }
      emptyCards.push(
        <div key="noItemsInList" className={s.noItemsInList}>
          <FormattedMessage {...messages.noItemsInList} />
        </div>,
      );
      return emptyCards;
    }

    const cards = recommendationItems
      .slice(0, NUMBER_OF_LIST_ITEMS)
      .map(item => (
        <div className={s.cardWrapper} key={item.id}>
          <Card
            itemData={item}
            itemState={userDataAsMap[item.id]}
            onItemStateChange={newItemState => {
              onItemStateChange(
                item.id,
                newItemState !== userDataAsMap[item.id]
                  ? newItemState
                  : ITEM_RESET,
              );
            }}
            removable={itemsRemovable}
            onRemoveItem={() => {
              onItemStateChange(item.id, ITEM_RESET);
            }}
            alwaysShowTitle={alwaysShowTitles}
            locked={loading || userDataIsLocked}
          />
        </div>
      ));

    return cards;
  }

  render() {
    const { userDataIsLocked } = this.props;
    const {
      recommendationItems,
      totalNumberOfItems,
      queryOffset,
      loading,
    } = this.state;

    const leftLocked =
      !recommendationItems || loading || queryOffset === 0 || userDataIsLocked;

    const rightLocked =
      !recommendationItems ||
      loading ||
      recommendationItems.length <= NUMBER_OF_LIST_ITEMS ||
      userDataIsLocked;

    const numberOfPages =
      Math.floor(Math.max(0, totalNumberOfItems - 1) / NUMBER_OF_LIST_ITEMS) +
      1;
    const currentPage = Math.floor(queryOffset / NUMBER_OF_LIST_ITEMS) + 1;

    return (
      <div className={s.genericCardsListWrapper}>
        <div className={s.pagesLabelContainer}>
          <span className={s.pageCounter}>
            <FormattedMessage {...messages.page} /> {currentPage} /{' '}
            {numberOfPages}
          </span>
        </div>
        <div className={s.genericCardsList}>
          <button
            className={s.leftButton}
            onClick={this.prevPage}
            disabled={leftLocked}
          >
            <FaCaretLeft className={s.arrow} />
          </button>
          <div className={s.cardsContainer}>{this.renderCards()}</div>
          <button
            className={s.rightButton}
            onClick={this.nextPage}
            disabled={rightLocked}
          >
            <FaCaretRight className={s.arrow} />
          </button>
          {/*
            <button
              className={s.addMoreButton}
              // onClick={this.onAddClick} // TODO
              disabled={loading || userDataIsLocked}
            >
              <FaPlus className={s.plus} />
            </button>
          */}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(GenericRecommendationItemList);
