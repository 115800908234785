/* eslint-disable import/prefer-default-export */
// common utility and helper functions

export const sleep = milliseconds =>
  new Promise(resolve => setTimeout(resolve, milliseconds));

export const isClientSide = () =>
  !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

export const isServerSide = () => !isClientSide();

export const getRandomDigits = (length = 20, min = 0, max = 9) =>
  Array.from(
    { length },
    () => Math.floor(Math.random() * (max - min + 1)) + min,
  ).join('');
