/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const getRecommendationsQuery = gql`
  query getRecommendations($data: RecommendationInput!) {
    getRecommendations(data: $data) {
      recommendationItems {
        id
        externalApiId
        externalApiLink
        title
        subTitle
        image
        releaseDate
        popularity
        i18n {
          id
          language
          title
          subTitle
        }
      }
      danubeItems {
        id
        age
        popularity
        seenCount
        positiveCorrelation
        negativeCorrelation
      }
    }
  }
`;
