/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { withRouter } from 'react-router';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-apollo';

import s from './ResetPassword.scss';
import messages from './messages';
import { resetPasswordMutation } from '../../data/mutations/userMutations';

class ResetPassword extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static renderErrorMessage(msg) {
    return <div className={s.formError}>{msg}</div>;
  }

  constructor(props) {
    super(props);

    this.state = {
      resetPasswordErrors: [],
      resetPasswordMessages: [],
    };
    this.resetPassword = this.resetPassword.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }
  async resetPassword(usernameOrEmail) {
    const response = await this.context.client.mutate({
      mutation: resetPasswordMutation,
      variables: {
        usernameOrEmail,
      },
    });

    return response.data.resetPassword;
  }

  async handleResetPassword(values, { setSubmitting }) {
    const { userNameOrEmail } = values;
    setSubmitting(true);
    this.setState({ resetPasswordErrors: [] });
    const result = await this.resetPassword(userNameOrEmail);
    if (!result) {
      this.setState({
        resetPasswordErrors: [messages.resetPasswordError],
      });
      setSubmitting(false);
    } else {
      this.setState({
        resetPasswordMessages: [messages.sendMailmessage],
      });
      setSubmitting(false);
    }
  }

  render() {
    const { resetPasswordErrors, resetPasswordMessages } = this.state;

    return (
      <div className={s.container}>
        <div>
          <h1>
            <FormattedMessage {...messages.headerPasswordReset} />
          </h1>
        </div>
        <div>
          <Formik
            initialValues={{ userNameOrEmail: '' }}
            validate={values => {
              const errors = {};
              if (!values.userNameOrEmail) {
                errors.userNameOrEmail = 'Required';
                this.setState({ resetPasswordErrors: [] });
              }
              return errors;
            }}
            onSubmit={this.handleResetPassword}
          >
            {({ isSubmitting }) => (
              <Form className={s.form}>
                <div className={s.formContent}>
                  <fieldset>
                    <div className={s.formGroup}>
                      <label htmlFor="userNameOrEmail">
                        <FormattedMessage {...messages.userOrMail} />
                      </label>
                      <div className={s.inputDiv}>
                        <Field
                          type="text"
                          id="userNameOrEmail"
                          name="userNameOrEmail"
                        />
                        <ErrorMessage
                          name="userNameOrEmail"
                          render={ResetPassword.renderErrorMessage}
                        />
                      </div>
                    </div>
                  </fieldset>
                  {resetPasswordErrors.length > 0 && (
                    <div>
                      {resetPasswordErrors.map(error => (
                        <div key={error.id} className={s.formError}>
                          <FormattedMessage {...error} />
                        </div>
                      ))}
                    </div>
                  )}
                  {resetPasswordMessages.length > 0 && (
                    <div>
                      {resetPasswordMessages.map(message => (
                        <div key={message.id} className={s.formMessage}>
                          <FormattedMessage {...message} />
                        </div>
                      ))}
                    </div>
                  )}
                  <button
                    type="submit"
                    className="btn btn-ur btn-round btn-wide"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage {...messages.sendMail} />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default compose(withRouter, withStyles(s))(ResetPassword);
